import React, { useContext, useEffect, useState } from 'react'
import { Table, Select, Button, Radio, Spin, RadioChangeEvent, Menu, Checkbox, CheckboxValueType, Dropdown, Input, AutoComplete, Collapse, Modal, Alert, Tooltip } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import '../../css/Report.css';
import { PlusOutlined, MinusOutlined, SettingOutlined, DownloadOutlined, UnderlineOutlined, AreaChartOutlined } from '@ant-design/icons';
import { API } from 'aws-amplify';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Row, Col, Form } from 'react-bootstrap';
import { getElasticAccessToken } from '../../graphql/queries';
import { AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AP_REQUESTED_INFORMATION, AR_REQUESTED_INFORMATION, AWAITING_AP_APPROVAL, AWAITING_AR_RESUBMISSION, BusinessCC, descSeparator, DRAFT, enableEditForAp, formatDate, formatTimezone, formDisable, groupByKey, sum, TBP, AWAITING_AP_RESUBMISSION, AP_SUBMITTED, allowAR, cancelTimerInterval, formatNum } from '../helper';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { right } from '@popperjs/core';
import ContextData from '../context/ContextData';



const { Panel } = Collapse;


const Reports = () => {


    const fromStatuses = process.env.REACT_APP_FORM_STATUS.split("|");
    const [visibleStatus, setVisibleStatus] = useState(false);
    const [showOrHideColumn, setShowOrHideColumn] = useState(false);
    const [visibleDate, setVisibleDate] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [fromDate, setFromDate] = useState(moment().subtract(parseInt(process.env.REACT_APP_FROM_REPORT_DATE), 'days').utc().format());
    const [toDate, setToDate] = useState(moment().add(parseInt(process.env.REACT_APP_TO_REPORT_DATE), 'days').utc().format());
    const [customDate, setCustomDate] = useState(false);
    const [customerAccNum, setCustomerAccNum] = useState(null);
    const [statusFilter, setStatusFilter] = useState(fromStatuses);
    const [recievingCompany, setRecievingCompany] = useState("");
    const [currency, setCurrency] = useState("");
    const [requestID, setRequestID] = useState("");
    const [reportData, setReportData] = useState([]);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [currentPage, setCurrentPage] = useState(1);
    const [authToken, setAuthToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [showOrHideRequestID, setShowOrHideRequestID] = useState(false);
    const [showOrHideDateRequested, setShowOrHideDateRequested] = useState(false);
    const [showOrHideRequestDescription, setShowOrHideRequestDescription] = useState(false);
    const [showOrHideRequestStatus, setShowOrHideRequestStatus] = useState(false);
    const [showOrHideRequestType, setShowOrHideRequestType] = useState(false);
    const [showOrHideSendingCompany, setShowOrHideSendingCompany] = useState(false);
    const [showOrHideReceivingCompany, setShowOrHideReceivingCompany] = useState(false);
    const [showOrHideArRequestor, setShowOrHideArRequestor] = useState(false);
    const [showOrHideApRequestor, setShowOrHideApRequestor] = useState(false);
    const [showOrHideArApprover, setShowOrHideArApprover] = useState(false);
    const [showOrHideApApprover, setShowOrHideApApprover] = useState(false);
    const [showOrHideAmount, setShowOrHideAmount] = useState(false);
    const [showOrHideCurrency, setShowOrHideCurrency] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [requestIDOptions, setRequestIDOptions] = useState([]);
    const [sendingCompanyCode, setSendingCompanyCode] = useState([]);
    const [receivingCompanyCode, setReceivingCompanyCode] = useState([]);
    const [formStatus, setFormStatus] = useState([]);
    const [arRequestor, setArRequestor] = useState([]);
    const [apRequestor, setApRequestor] = useState([]);
    const [arApprover, setArApprover] = useState([]);
    const [apApprover, setApApprover] = useState([]);


    const contextValue = useContext(ContextData);
    const { timerCtxt, currencyFormat } = contextValue;

    const requestTypeValues = ['Invoice', 'Credit Note'];
    const requestTypeOptions = [
        {
            label: 'invoice',
            value: 'invoice',
        },
        {
            label: 'Credit Note',
            value: 'creditNote',
        },

    ];
    const [sendingCompany, setSendingCompany] = useState("");
    const [arRequestorValue, setArRequestorValue] = useState("");
    const [apRequestorValue, setApRequestorValue] = useState("");
    const [arApproverValue, setArApproverValue] = useState("");
    const [apApproverValue, setApApproverValue] = useState("");
    const [status, setStatus] = useState("");
    const [requestType, setRequestType] = useState("");

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';


    // This method is for exporting the data to an EXCEL File
    const exportToXLSX = (jsonData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(jsonData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }


    const { option } = Select;

    const navigate = useNavigate();


    const handleCurrencyChange = (value) => {
        setCurrency(value);
    };

    const handleArRequestorChange = (value) => {
        setArRequestorValue(value);
    };

    const handleApRequestorChange = (value) => {
        setApRequestorValue(value);
    };

    const handleApApproverChange = (value) => {
        setApApproverValue(value);
    };

    const handleArApproverChange = (value) => {
        setArApproverValue(value);
    };

    const handleStatusChange = (value) => {
        setStatus(value);
    };


    const handleSendingCompany = (value) => {
        setSendingCompany(value);
    };

    const handleRequestID = (e) => {
        setRequestID(e.target.value);
        // console.log('handle request', e.target.value);
    };

    const handleReceivingCompany = (value) => {
        setRecievingCompany(value);
    };


    // to clear all the leftout timeouts from a component
    useEffect(() => {

        const timerCancel = setInterval(() => {
            const { timerCtxt } = contextValue;
            for (let j = 0; j < timerCtxt.length; j++) {
                clearTimeout(timerCtxt[j])
            }
        }, cancelTimerInterval)

        return () => clearInterval(timerCancel)

    }, [timerCtxt])

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenSize(window.innerWidth)
        })
        const getAuthToken = async () => {
            const authTkn = await handleauthToken();
            handleLoadElasticData(authTkn);
        }

        getAuthToken();
    }, [])

    const closeAlert = () => {
        setIsError(false);
    }

    const getApproversArr = (record, type) => {
        let updatedArr = [];

        const approvers =
            (type === "AR") ? record?.arapprovers_id?.raw : record?.apapprovers_id?.raw;


        if (approvers) {
            for (let i = 0; i < approvers.length; i++) {
                let approver = approvers[i]?.toString();

                if (i !== approvers.length - 1) {
                    approver += ", ";
                }

                updatedArr.push(approver);
            }
        }

        return (updatedArr.length !== 0) ? updatedArr : "null";
    }



    // Only for Mobile (upto 755px S-W)
    // This is for generated reports table
    const mobileColumn = [
        {
            title: 'request ID',
            dataIndex: 'requestid',
            key: 'requestid',
            'hidden': true
        },
        {
            title: "",
            key: 'mobile',
            render: (record) => (
                <table className='mobileTable'>
                    <tbody style={{ position: "relative" }}>
                        <tr>
                            <td align="left" className='mobileHeading'>Request ID</td>
                            <td align="left">{record?.requestid?.raw}</td>
                        </tr>
                        <tr>
                            <td align="left" className='mobileHeading'>Request Date</td>
                            <td align="left">{record?.requestdate?.raw}</td>
                        </tr>
                        <tr>
                            <td align="left" className='mobileHeading'>Request Description</td>
                            <td align="left">{record?.requestdescription?.raw}</td>
                        </tr>
                        <tr>
                            <td align="left" className='mobileHeading'>Request Type</td>
                            <td align="left">{record?.requesttype?.raw}</td>
                        </tr>

                        <tr>
                            <td align="left" className='mobileHeading'>Status</td>
                            <td align="left">{record?.formstatus?.raw}</td>
                        </tr>
                        <tr>
                            <td align="left" className='mobileHeading'>Sending Company</td>
                            <td align="left">{record?.sendingcompanycode?.raw}</td>
                        </tr>
                        <tr>
                            <td align="left" className='mobileHeading'>Receiving Company</td>
                            <td align="left">{record?.receivingcompanycode?.raw}</td>
                        </tr>

                        <tr>
                            <td align="left" className='mobileHeading'>AR Requestor</td>
                            <td align="left">{record?.arrequestor?.raw}</td>
                        </tr>
                        <tr>
                            <td align="left" className='mobileHeading'>AP Requestor</td>
                            <td align="left">{record?.aprequestor?.raw}</td>
                        </tr>

                        <tr>
                            <td align="left" className='mobileHeading'>AR Approver(s)</td>
                            <td align="left">{getApproversArr(record, "AR")}</td>
                        </tr>
                        <tr>
                            <td align="left" className='mobileHeading'>AP Approver(s)</td>
                            <td align="left">{getApproversArr(record, "AP")}</td>
                        </tr>

                        <tr>
                            <td align="left" className='mobileHeading'>Amount</td>
                            <td align="left">{parseFloat(formatNum(record?.totalamt?.raw)).toLocaleString(currencyFormat)}</td>
                        </tr>
                        <tr>
                            <td align="left" className='mobileHeading'>Currency</td>
                            <td align="left">{record?.currency?.raw}</td>
                        </tr>




                    </tbody>
                </table>
            ),
        }
    ].filter(item => !item.hidden);


    // Only for Dektops and Tablet (beyond 755px S-W)
    // This is for generated reports table
    const ReportColumns = [
        {
            title: 'Request ID',
            dataIndex: 'requestid',
            key: 'requestid',
            width: '5%',
            hidden: showOrHideRequestID,
            render: (text, record) => {
                return <Link
                    className='cc-link'
                    to={`/review/${record?.id?.raw}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {record?.requestid?.raw}
                </Link>
            },
            defaultSortOrder: 'descend',
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.requestid?.raw - b.requestid?.raw,

        },

        {
            title: 'Request Date',
            dataIndex: 'requestdate',
            key: 'requestdate',
            width: '5%',
            hidden: showOrHideDateRequested,
            render: (text, record) => {
                return moment(record?.requestdate?.raw).format('MM/DD/YYYY')
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => new Date(a.requestdate?.raw) - new Date(b.requestdate?.raw),

        },

        {
            title: 'Request Description',
            dataIndex: 'requestdescription',
            key: 'requestdescription',
            width: '5%',
            hidden: showOrHideRequestDescription,
            render: (text, record) => {
                return (record?.requestdescription?.raw)
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.requestdescription?.raw?.localeCompare(b.requestdescription?.raw)

        },
        {
            title: 'Request Type',
            dataIndex: 'requesttype',
            key: 'requesttype',
            width: '5%',
            hidden: showOrHideRequestType,
            render: (text, record) => {
                return (record?.requesttype?.raw)
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.requesttype?.raw?.localeCompare(b.requesttype?.raw)

        },

        {
            title: 'Status',
            dataIndex: 'formstatus',
            key: 'status',
            width: '5%',
            hidden: showOrHideRequestStatus,
            render: (text, record) => {
                return (record?.formstatus?.raw)
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.formstatus?.raw?.localeCompare(b.formstatus?.raw)

        },

        {
            title: 'Sending Company',
            dataIndex: 'sendingcompanycode',
            key: 'sendingcompanycode',
            width: '5%',
            hidden: showOrHideSendingCompany,
            render: (text, record) => {
                return (record?.sendingcompanycode?.raw)
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.sendingcompanycode?.raw?.localeCompare(b.sendingcompanycode?.raw)

        },

        {
            title: 'Receiving Company',
            dataIndex: 'receivingcompanycode',
            key: 'receivingcompanycode',
            width: '6%',
            hidden: showOrHideReceivingCompany,
            render: (text, record) => {
                return (record?.receivingcompanycode?.raw)
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.receivingcompanycode?.raw?.localeCompare(b.receivingcompanycode?.raw)

        },

        {
            title: 'AR Requestor',
            dataIndex: 'arrequestor',
            key: 'arrequestor',
            width: '6%',
            hidden: showOrHideArRequestor,
            render: (text, record) => {
                return (record?.arrequestor?.raw)
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.arrequestor?.raw?.localeCompare(b.arrequestor?.raw)

        },

        {
            title: 'AP Requestor',
            dataIndex: 'aprequestor',
            key: 'aprequestorid',
            width: '6%',
            hidden: showOrHideApRequestor,
            render: (text, record) => {
                return (record?.aprequestor?.raw)
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.aprequestor?.raw?.localeCompare(b.aprequestor?.raw)

        },

        {
            title: 'AR Approver(s)',
            dataIndex: 'arapprover',
            key: 'arapproverid',
            width: '6%',
            hidden: showOrHideArApprover,
            render: (text, record) => {
                const approvers = getApproversArr(record, "AR");

                return approvers;
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => {
                a.arapprovers_id?.raw[0]?.localeCompare(b.arapprovers_id?.raw[0])
            }

        },

        {
            title: 'AP Approver(s)',
            dataIndex: 'apapprover',
            key: 'apapproverid',
            width: '6%',
            hidden: showOrHideApApprover,
            render: (text, record) => {
                const approvers = getApproversArr(record, "AP");

                return approvers;
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => {
                a.apapprovers_id?.raw[0]?.localeCompare(b.apapprovers_id?.raw[0]);
            }

        },

        {
            title: 'Amount',
            dataIndex: 'totalamt',
            key: 'totalamt',
            width: '5.5%',
            className: 'amt-col',
            hidden: showOrHideAmount,
            render: (text, record) => {
                return parseFloat(formatNum(record?.totalamt?.raw)).toLocaleString(currencyFormat);
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.totalamt?.raw?.localeCompare(b.totalamt?.raw),


        },

        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
            width: '5.5%',
            hidden: showOrHideCurrency,
            render: (text, record) => {
                return (record?.currency?.raw)
            },
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: (a, b) => a.currency?.raw?.localeCompare(b.currency?.raw)
        },
    ].filter(item => !item.hidden);






    const handleStatusVisibleChange = (flag) => {
        setVisibleStatus(flag);
    }

    const handleShowOrHideColumnChange = (flag) => {
        setShowOrHideColumn(flag);
    }
    const handleDateVisibleChange = (flag) => {
        setVisibleDate(flag)
    }





    const onStatusChange = (checkedValues) => {
        // console.log('checked = ', checkedValues);
        setStatusFilter(checkedValues);
    }



    const validateFilters = () => {
        //  const customerSet = customersNumbers()
        //  customerSet.push(null);
        //  customerSet.push('');

        const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;


        let flag = 1;



        if (statusFilter.length === 0) {
            flag = 0;
            Modal.confirm({
                title: "Please select atleaset one status filter",
                okType: "danger"
            })
        }
        if (!fromDate && !toDate) {
            flag = 0;
            Modal.confirm({
                title: "Date range cannot be empty",
                okType: "danger"
            })
        } else
            if (fromDate || toDate) {

                if (!(fromDate && toDate)) {

                    // console.log("Both from date and to date should be present");
                    flag = 0;
                    Modal.confirm({
                        title: "Please select the date range",
                        okType: "danger"
                    })
                } else if (Date.parse(toDate) < Date.parse(fromDate)) {
                    flag = 0;
                    Modal.confirm({
                        title: "'To' date should be ahead of 'From' date",
                        okType: "danger"
                    })
                }
            }



        return flag;
    }


    // This function is for generating a new TOKEN
    const handleauthToken = async () => {
        setIsLoading(true);

        const authtkn = await API.graphql({
            query: getElasticAccessToken,
            variables: { input: refreshToken }
        })
            .then(response => {
                // console.log("auth Token response", response?.data?.getElasticAccessToken?.access_token);

                const token = response?.data?.getElasticAccessToken?.access_token
                const refreshTkn = response?.data?.getElasticAccessToken?.refresh_token

                setAuthToken(token);
                setRefreshToken(refreshTkn);
                setIsLoading(false);


                return token;
            })
            .catch(err => {
                setIsLoading(false);
                console.log("err getting auth Token", err)
            });



        return authtkn;

    }


    // API Call to load all Drop Downs' data
    const handleLoadElasticData = (authTkn) => {
        if (1) {
            // console.log("valdation passed");

            setShowSpinner(true);

            const reportPayload = {
                "query": " ",
                "facets": {
                    "currency": [{ "type": "value" }],
                    "receivingcompanycode": [{ "type": "value" }],
                    "sendingcompanycode": [{ "type": "value" }],
                    "formstatus": [{ "type": "value" }],
                    "arrequestor": [{ "type": "value" }],
                    "aprequestor": [{ "type": "value" }],
                    "arapprovers_id": [{ "type": "value" }],
                    "apapprovers_id": [{ "type": "value" }]
                }
            };

            // console.log('report', reportPayload);

            const reportParams = {
                method: 'POST',
                content: 'Application/JSON',
                body: JSON.stringify(reportPayload),
                headers: {
                    Authorization: `Bearer ${authTkn}`,
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            };

            fetch(process.env.REACT_APP_CrossChargeElasticReport_Endpoint, reportParams)
                .then(response => response.json())
                .then(async (response) => {
                    if (response.error != 'InvalidAuthenticationToken' || 'Invalid credentials') {
                        // console.log('reportData ', response);


                        const currencyData = response?.facets?.currency[0]?.data?.map(c => {
                            return {
                                value: c.value,
                                label: c.value,
                            }

                        });

                        const sendingCompanyData = response?.facets?.sendingcompanycode[0]?.data?.map(s => {
                            return {
                                value: s.value,
                                label: s.value,
                            }

                        });

                        const receivingCompanyData = response?.facets?.receivingcompanycode[0]?.data?.map(r => {
                            return {
                                value: r.value,
                                label: r.value,
                            }

                        });

                        const apRequestorData = response?.facets?.aprequestor[0]?.data?.map(ap => {
                            return {
                                value: ap.value,
                                label: ap.value,
                            }

                        });

                        const arRequestorData = response?.facets?.arrequestor[0]?.data?.map(ar => {
                            return {
                                value: ar.value,
                                label: ar.value,
                            }

                        });

                        const formStatusData = response?.facets?.formstatus[0]?.data?.map(f => {
                            return {
                                value: f.value,
                                label: f.value,
                            }
                        });

                        const apApproversData = response?.facets?.apapprovers_id[0]?.data?.map((f) => {
                            return {
                                value: f.value,
                                label: f.value,
                            }
                        });

                        const arApproversData = response?.facets?.arapprovers_id[0]?.data?.map((f) => {
                            return {
                                value: f.value,
                                label: f.value,
                            }
                        });


                        setCurrencyOptions(currencyData);
                        setSendingCompanyCode(sendingCompanyData);
                        setReceivingCompanyCode(receivingCompanyData);
                        setApRequestor(apRequestorData);
                        setArRequestor(arRequestorData);
                        setFormStatus(formStatusData);
                        setApApprover(apApproversData);
                        setArApprover(arApproversData);
                    }
                    else {
                        await handleauthToken();
                        handleLoadElasticData();
                    }
                })
                .catch((err) => {
                    console.log("handleLoadElasticData Error", err);
                });

        }

        else {
            // console.log("valdation failed")

        }
    }


    // API Call to generate the Report
    const handleGenerateReport = () => {
        if (1) {
            // console.log("valdation passed");

            setIsLoading(true);

            const reportPayload = {
                "query": " ",
                page: { size: 1000 },

                "filters": {
                    "all": [
                        { "elasticrequest_date": { from: fromDate, to: toDate } },
                        ...(requestID ? [{ "requestid": requestID?.split(',') }] : []),
                        ...(sendingCompany ? [{ "sendingcompanycode": sendingCompany }] : []),
                        ...(recievingCompany ? [{ "receivingcompanycode": recievingCompany }] : []),
                        ...(currency ? [{ "currency": currency }] : []),
                        ...(requestType?.length > 0 ? [{ "requesttype": requestType }] : []),
                        ...(arRequestorValue ? [{ "arrequestor": arRequestorValue }] : []),
                        ...(apRequestorValue ? [{ "aprequestor": apRequestorValue }] : []),
                        ...(status ? [{ "formstatus": status }] : []),
                        ...(arApproverValue ? [{ arapprovers_id: arApproverValue }] : []),
                        ...(apApproverValue ? [{ apapprovers_id: apApproverValue }] : [])
                    ],
                },
            };

            // console.log("Report payload", reportPayload);

            const reportParams = {
                method: 'POST',
                content: 'Application/JSON',
                body: JSON.stringify(reportPayload),


                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            };

            fetch(process.env.REACT_APP_CrossChargeElasticReport_Endpoint, reportParams)
                .then(response => response.json())
                .then(async (response) => {
                    if (response.error != 'InvalidAuthenticationToken' || 'Invalid credentials') {
                        // console.log('reportData ', response);
                        setReportData(response?.results);

                    }
                    else {
                        await handleauthToken();
                        handleGenerateReport();
                    }
                    setIsLoading(false);
                })

                .catch(e => {
                    console.log("CrossChargeElasticReport_Endpoint error: ",e);
                    setIsLoading(false)
                });

        }

        else {
            console.log("valdation failed")

        }
    }

    /* This function is used to create json data for 
    exporting to XLSX file -> using handleExport() */
    const flatJSON = (originalData) => {
        // let cpy = JSON.parse(originalData);
        const results = originalData?.map(item => {
            return {
                RequestId: item.requestid?.raw,
                RequestDate: item.requestdate?.raw,
                RequestDescription: item.requestdescription?.raw,
                RequestType: item.requesttype?.raw,
                Status: item.formstatus?.raw,
                SendingCompany: item.sendingcompanycode?.raw,
                ReceivingCompany: item.receivingcompanycode?.raw,
                ARRequestor: item.arrequestor?.raw,
                APRequestor: item.aprequestor?.raw,
                Amount: item.totalamt?.raw,
                Currency: item.currency?.raw


            }

        })

        // console.log('results', results);
        return results;
    }

    // API Call to handle the Export
    const handleExport = async () => {
        setIsLoading(true);
        if (1) {
            // console.log("valdation passed");

            setShowSpinner(true);

            const reportPayload = {
                "query": " ",
                page: { size: 1000 },
                "filters": {
                    "all": [
                        { "elasticrequest_date": { from: fromDate, to: toDate } },
                        ...(requestID ? [{ "requestid": requestID?.split(',') }] : []),
                        ...(sendingCompany ? [{ "sendingcompanycode": sendingCompany }] : []),
                        ...(recievingCompany ? [{ "receivingcompanycode": recievingCompany }] : []),
                        ...(currency ? [{ "currency": currency }] : []),
                        ...(requestType?.length > 0 ? [{ "requesttype": requestType }] : []),
                        ...(arRequestorValue ? [{ "arrequestor": arRequestorValue }] : []),
                        ...(apRequestorValue ? [{ "aprequestor": apRequestorValue }] : []),
                        ...(status ? [{ "formstatus": status }] : []),
                    ],
                },
            };

            // console.log('report', reportPayload);

            const reportParams = {
                method: 'POST',
                content: 'Application/JSON',
                body: JSON.stringify(reportPayload),
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "accept": "application/json",
                    "content-type": "application/json"
                }
            };

            fetch(process.env.REACT_APP_CrossChargeElasticReport_Endpoint, reportParams)
                .then(response => response.json())
                .then(async (response) => {
                    if (response.error != 'InvalidAuthenticationToken' || 'Invalid credentials') {
                        // console.log('reportData ', response);
                        const dataToExport = await flatJSON(response?.results);
                        exportToXLSX(dataToExport, "report");
                    }
                    else {
                        await handleauthToken();
                        handleExport();
                    }
                    setIsLoading(false);
                })


                .catch(e => {
                    console.log("CrossChargeElasticReport_Endpoint error:",e);
                    setIsLoading(false)
                });

        }

        else {
            console.log("valdation failed")
        }
    }



    const checkbox = (
        <div className='report'>
            <Menu>
                <Menu.Item key="status" >
                    <Checkbox.Group
                        options={fromStatuses}
                        defaultValue={fromStatuses}
                        onChange={onStatusChange}
                    />
                </Menu.Item>
            </Menu>

            <Button
                style={{ marginLeft: "16px" }}
                type="primary" size='small'
                onClick={() => {
                    setVisibleStatus(false)
                }}
            >
                OK
            </Button>
        </div>

    )




    // This is for "customize columns" logic
    const columnCheckbox = (
        <div className='report'>
            <Menu>

                <Menu.Item key="status" >

                    <div className='d-flex flex-column columnScroll '>


                        <Checkbox checked={!showOrHideRequestID} onChange={(e) => {
                            setShowOrHideRequestID(!e.target.checked);
                        }}>Request Id</Checkbox>

                        <Checkbox checked={!showOrHideDateRequested} onChange={(e) => {
                            setShowOrHideDateRequested(!e.target.checked);
                        }}>Request Date</Checkbox>



                        <Checkbox checked={!showOrHideRequestType} onChange={(e) => {
                            setShowOrHideRequestType(!e.target.checked);
                        }}>Request Type</Checkbox>

                        <Checkbox checked={!showOrHideRequestStatus} onChange={(e) => {
                            setShowOrHideRequestStatus(!e.target.checked);
                        }}>Status</Checkbox>

                        <Checkbox checked={!showOrHideSendingCompany} onChange={(e) => {
                            setShowOrHideSendingCompany(!e.target.checked);
                        }}>Sending Company</Checkbox>

                        <Checkbox checked={!showOrHideReceivingCompany} onChange={(e) => {
                            setShowOrHideReceivingCompany(!e.target.checked);
                        }}>Receiving Company</Checkbox>

                        <Checkbox checked={!showOrHideArRequestor} onChange={(e) => {
                            setShowOrHideArRequestor(!e.target.checked);
                        }}>AR Requestor</Checkbox>

                        <Checkbox checked={!showOrHideApRequestor} onChange={(e) => {
                            setShowOrHideApRequestor(!e.target.checked);
                        }}>AP Requestor</Checkbox>

                        <Checkbox checked={!showOrHideArApprover} onChange={(e) => {
                            setShowOrHideArApprover(!e.target.checked);
                        }}>AR Approver(s)</Checkbox>
                        <Checkbox checked={!showOrHideApApprover} onChange={(e) => {
                            setShowOrHideApApprover(!e.target.checked);
                        }}>AP Approver(s)</Checkbox>

                        <Checkbox checked={!showOrHideAmount} onChange={(e) => {
                            setShowOrHideAmount(!e.target.checked);
                        }}>Amount</Checkbox>

                        <Checkbox checked={!showOrHideCurrency} onChange={(e) => {
                            setShowOrHideCurrency(!e.target.checked);
                        }}>Currency</Checkbox>


                    </div>
                </Menu.Item>
            </Menu>
        </div>

    );

    const dateRange = (
        <div className='report'>
            <Menu>
                <Menu.Item key="dateRange" >
                    <table>
                        <tbody>
                            <tr>
                                <td align="left">From: </td>
                                <td align="left"><Input
                                    type="date"
                                    // value={fromDate}
                                    onChange={(e) => {
                                        // console.log(moment(e.target.value).utc().format());

                                        setFromDate(moment(e.target.value).utc().format())
                                    }}
                                /></td>
                            </tr>
                            <tr>
                                <td align="left">To: </td>
                                <td align="left"><Input
                                    type="date"
                                    //  value={toDate}
                                    onChange={(e) => {

                                        setToDate(moment(e.target.value).utc().format())
                                    }}
                                /></td>
                            </tr>
                        </tbody>

                    </table>

                </Menu.Item>
            </Menu>
            {/* <Button style={{ marginLeft: "16px" }} type="primary" size='small' onClick={() => { setVisibleDate(false); }}>OK</Button> */}
        </div>

    );

    return (
        <Spin spinning={isLoading} size='large'>
            <div className='report'>
                <div className='filterContainer'>
                    <p className='request-heading'>Report Selections</p>
                </div>

                <hr></hr>

                {/* All Filters */}
                <div className='row filterContainer'>
                    {/* Left side */}
                    <div className='col-md-6'>
                        <div className='row verticalHeight'>
                            <div className='col-sm-3 adjustWidth alignLeft adjustMargin labelAdjust'>
                                <Form.Label>Request Date:</Form.Label>
                            </div>

                            <div className='col-sm-8 d-flex alignRequestDate requestDateLabel dateMobileAdjust '>
                                <Radio.Group defaultValue={"Last Month"}>
                                    <Radio className='reportRadio radioFontAdjust lastWeekAdjust'
                                        onChange={e => {
                                            setCustomDate(false);
                                            const fromdate = moment().subtract(7, 'days').utc().format();
                                            setFromDate(fromdate)
                                            const toDate = moment().utc().format();
                                            setToDate(toDate)
                                        }}
                                        value={"Last Week"}
                                    >
                                        Last Week
                                    </Radio>

                                    <Radio checked className='reportRadio radioFontAdjust lastMonthAdjust '
                                        onChange={e => {
                                            setCustomDate(false);
                                            const fromdate = moment().subtract(30, 'days').utc().format();
                                            setFromDate(fromdate)
                                            const toDate = moment().utc().format();
                                            setToDate(toDate)
                                        }}
                                        value={"Last Month"}
                                    >
                                        Last Month
                                    </Radio>

                                    <Radio className='reportRadio radioFontAdjust lastYearAdjust'
                                        onChange={(e) => {
                                            setCustomDate(false);
                                            const fromdate = moment().subtract(365, 'days').utc().format();;
                                            setFromDate(fromdate)
                                            const toDate = moment().utc().format();;
                                            setToDate(toDate)
                                        }}
                                        value={"Last Year"}
                                    >
                                        Last Year
                                    </Radio>

                                    <Radio
                                        className='reportRadio radioFontAdjust customMobileAdjust'
                                        onChange={e => { setCustomDate(true) }}
                                    >
                                        Custom Date
                                    </Radio>
                                </Radio.Group>
                            </div>

                            { }


                            <div className='col-sm-6 adjustMargin'>
                                <div className='col-sm-8 dateRangeAdjust alignLeft requestCustomDate'>
                                    { }


                                    {
                                        customDate && dateRange
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='row verticalHeight'>
                            <div className="col-sm-3 adjustWidth alignLeft adjustMargin labelAdjust ">
                                <Form.Label>Request Type:</Form.Label>
                            </div>
                            <div className='col-sm-8 alignLeft statusContainer statusMobileAdjust'>
                                <Checkbox.Group style={{ display: 'block' }} options={requestTypeOptions} defaultValue={requestTypeValues}
                                    onChange={(checkedValues) => {
                                        // console.log(checkedValues);
                                        setRequestType(checkedValues)
                                    }} />
                            </div>
                        </div>

                        <div className='row verticalHeight'>
                            <div className="col-sm-3 adjustWidth alignLeft adjustMargin labelAdjust ">
                                <Form.Label>Request ID:</Form.Label>
                            </div>
                            <div className='col-sm-8 alignLeft statusContainer typeMobileAdjust'>
                                <Input className='cc-input-field' value={requestID} onChange={handleRequestID} />
                                <Tooltip title="Please Enter Multiple Values Separated by Comma e.g. ID1,ID2...">

                                    <InfoCircleOutlined
                                        className='cc-icons'
                                        style={{ marginLeft: "10px" }}
                                    />
                                </Tooltip>

                            </div>
                        </div>

                        <div className='row verticalHeight'>
                            <div className="col-sm-3 adjustWidth alignLeft adjustMargin labelAdjust ">
                                <Form.Label>Status:</Form.Label>
                            </div>
                            <div className='col-sm-8 alignLeft statusContainer typeMobileAdjust'>
                                <AutoComplete
                                    showSearch
                                    className='cc-input-field'
                                    optionFilterProp="children"
                                    value={status}
                                    onChange={handleStatusChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    // optionLabelProp='value'
                                    options={formStatus}
                                // disabled={formDisableCondition}
                                />

                            </div>
                        </div>
                    </div>

                    {/* Right side */}
                    <div className='col-md-5'>
                        <div className='row verticalHeight'>
                            <div className='col-sm-4 alignLeft adjustMargin labelAdjust'>
                                <Form.Label>Sending Company:</Form.Label>
                            </div>

                            <div className='col-sm-6 alignLeft statusContainer typeMobileAdjust'>
                                <AutoComplete
                                    showSearch
                                    className='cc-input-field'
                                    optionFilterProp="children"
                                    value={sendingCompany}
                                    onChange={handleSendingCompany}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    // optionLabelProp='value'
                                    options={sendingCompanyCode}
                                // disabled={formDisableCondition}
                                />

                            </div>
                        </div>

                        <div className='row verticalHeight'>
                            <div className='col-sm-4 alignLeft adjustMargin labelAdjust'>
                                <Form.Label>Receiving Company:</Form.Label>
                            </div>


                            <div className='col-sm-6 alignLeft statusContainer typeMobileAdjust'>
                                <AutoComplete
                                    showSearch
                                    className='cc-input-field'
                                    optionFilterProp="children"
                                    value={recievingCompany}
                                    onChange={handleReceivingCompany}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={receivingCompanyCode}

                                />

                            </div>
                        </div>

                        <div className='row verticalHeight'>
                            <div className='col-sm-4 alignLeft adjustMargin labelAdjust'>
                                <Form.Label>Currency:</Form.Label>
                            </div>
                            <div className='col-sm-6 alignLeft statusContainer typeMobileAdjust'>
                                <AutoComplete
                                    showSearch
                                    className='cc-input-field'
                                    optionFilterProp="children"
                                    value={currency}
                                    onChange={handleCurrencyChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    // optionLabelProp='value'
                                    options={currencyOptions}
                                // disabled={formDisableCondition}
                                />

                            </div>

                        </div>

                        <div className='row verticalHeight'>
                            <div className='col-sm-4 alignLeft adjustMargin labelAdjust'>
                                <Form.Label>AR Requestor:</Form.Label>
                            </div>
                            <div className='col-sm-6 alignLeft statusContainer typeMobileAdjust'>
                                <AutoComplete
                                    showSearch
                                    className='cc-input-field'
                                    optionFilterProp="children"
                                    value={arRequestorValue}
                                    onChange={handleArRequestorChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    // optionLabelProp='value'
                                    options={arRequestor}
                                // disabled={formDisableCondition}
                                />

                            </div>

                        </div>

                        <div className='row verticalHeight'>
                            <div className='col-sm-4 alignLeft adjustMargin labelAdjust'>
                                <Form.Label>AP Requestor:</Form.Label>
                            </div>

                            <div className='col-sm-6 alignLeft statusContainer typeMobileAdjust'>
                                <AutoComplete
                                    showSearch
                                    className='cc-input-field'
                                    optionFilterProp="children"
                                    value={apRequestorValue}
                                    onChange={handleApRequestorChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    // optionLabelProp='value'
                                    options={apRequestor}
                                // disabled={formDisableCondition}
                                />


                            </div>

                        </div>



                        <div className='row verticalHeight'>
                            <div className='col-sm-4 alignLeft adjustMargin labelAdjust'>
                                <Form.Label>AR Approver:</Form.Label>
                            </div>

                            <div className='col-sm-6 alignLeft statusContainer typeMobileAdjust'>
                                <AutoComplete
                                    showSearch
                                    className='cc-input-field'
                                    optionFilterProp="children"
                                    value={arApproverValue}
                                    onChange={handleArApproverChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={arApprover}
                                />


                            </div>

                        </div>

                        <div className='row verticalHeight'>
                            <div className='col-sm-4 alignLeft adjustMargin labelAdjust'>
                                <Form.Label>AP Approver:</Form.Label>
                            </div>

                            <div className='col-sm-6 alignLeft statusContainer typeMobileAdjust'>
                                <AutoComplete
                                    showSearch
                                    className='cc-input-field'
                                    optionFilterProp="children"
                                    value={apApproverValue}
                                    onChange={handleApApproverChange}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={apApprover}
                                />


                            </div>

                        </div>
                    </div>
                </div>


                <div className='filterContainer'>
                    <div className=' filterBtns'>

                    </div>

                    <div className='filterBtns' >
                        { }
                    </div>

                    <div className='filterBtns' >

                    </div>
                    <div className='filterBtns'>

                    </div>
                </div>

                <div className='filterContainer verticalHeight'>

                    <div className='filterBtns' >
                        <Button
                            onClick={handleGenerateReport}
                            className='actionBtn'
                        >
                            Generate Report
                        </Button>
                    </div>



                </div>

                <hr></hr>

                <div className="row">
                    <div className="col-sm-6 alignLeft adjustMargin">
                        <p className='request-heading'>Report Results</p>
                    </div>
                    <div className="col-sm-5 d-flex customizeMargin ">
                        <SettingOutlined style={{ marginTop: '5px' }} />
                        <div className='show-hide-btn' >
                            <Dropdown className='reportDropdown' overlay={columnCheckbox} trigger={["click"]}
                                onVisibleChange={handleShowOrHideColumnChange}
                                visible={showOrHideColumn}>

                                <div style={{ textDecoration: 'Underline', cursor: 'pointer' }} className='customizeColumn'
                                    title="Customize Columns"
                                    onClick={(e) => { e.preventDefault(); }}>
                                    Customize Columns
                                </div>

                            </Dropdown>
                        </div>
                        <DownloadOutlined style={{ marginTop: '5px' }} />
                        <div style={{ textDecoration: 'Underline', cursor: 'pointer' }} className='customizeColumn' onClick={handleExport}>Export Report</div>
                        {/* <AreaChartOutlined style={{ marginTop: '5px'}}/>
                <div style={{textDecoration:'Underline', cursor:'pointer'}}className='customizeColumn' onClick={() => navigate('/ReportsDashboard')}>Reports Chart</div> */}
                    </div>
                </div>


                <hr></hr>


                {/* This is the report table (the data is taken from --> reportData).
                Here columns have 2 states -> (1) mobiles, (2) other screen sizes */}
                <Table
                    id="report-table"
                    size="large"
                    columns={screenSize > 767 ? ReportColumns : mobileColumn}
                    dataSource={reportData}
                    rowKey="executionId"
                    pagination={{
                        showSizeChanger: true,
                        current: currentPage,
                        onChange: (page, size) => {
                            setCurrentPage(page);
                        }
                    }}

                    bordered />
            </div>
        </Spin>
    );
}




export default Reports;