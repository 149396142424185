import React, { useContext } from 'react';
import ContextData from '../context/ContextData';
import { AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED, capitalize, FAILED, groupByKey, showErrorMsg, TBP } from '../helper';
import LabelInfoPair from './LabelInfoPair'

const RequestInfo = (props) => {
    const contextValue = useContext(ContextData);
    const { currencyFormat } = contextValue;

    // console.log("revw",props.reviewData)
    const {reviewData} = props;

    let amount = '';
    if (reviewData?.arLineItems) {
        const arAmountObj = groupByKey(reviewData?.arLineItems, "postingKey")
        amount = arAmountObj['01'] ? arAmountObj['01'] : (arAmountObj['11'] ? arAmountObj['11'] : '')
    }

  return (
    <div className="cc-card-body">
        <LabelInfoPair 
            label = 'Request ID'
            information = {reviewData?.requestId}
        />
        
        <LabelInfoPair 
            label = 'Request Type'
            information = {reviewData?.requestType}
        />

        <LabelInfoPair 
            label = 'Request Date'
            information = {reviewData?.requestDate}
        />

        <LabelInfoPair 
            label = 'Invoice Number'
            information = {reviewData?.invoiceNo ? reviewData?.invoiceNo : TBP}
            note={reviewData?.invoiceNo ? false : true}
        />

        <div className='d-flex'>

            <LabelInfoPair
                label='Amount'
                information={amount && (parseFloat(amount).toLocaleString(currencyFormat) + ' ' + reviewData?.currency)}
            />

            {
                reviewData?.exchangeRate &&
                <LabelInfoPair
                label={<>&nbsp;/ Exchange Rate</>}
                information={reviewData?.exchangeRate}
            />
            }
        </div>

        <LabelInfoPair 
            label = 'Description'
            information = {reviewData?.requestDescription}
        />

        <LabelInfoPair 
            label = 'Status'
            information = {
                ([AWAITING_FOR_AR_DOC_FAILED, AWAITING_FOR_AP_DOC_FAILED, AWAITING_FOR_ATTACHMENT_FAILED, FAILED].includes(reviewData?.formStatus)) ?
                    <span className="cc-link" onClick={()=>showErrorMsg(reviewData?.sapfailureMessage)}>{capitalize(reviewData?.formStatus)}</span>
                :
                    <span>{capitalize(reviewData?.formStatus)}</span>
                }
        />

    </div>
  )
}

export default RequestInfo