import React, { useContext, useEffect, useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Progress, Spin, Tooltip, Table, Button, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faXmark } from '@fortawesome/free-solid-svg-icons'
import * as XLSX from "xlsx";
import Heading from '../HOC/Heading'

import '../../css/excelUpload.css'
import { getExcelProcessColumns } from '../columns'
import HorizontalLine from '../HOC/HorizontalLine'
import {
    addPaddingToNum,
    ADMIN_REQUESTED_AP_RESUBMISSION,
    apPostingKeys,
    AP_DRAFT,
    AWAITING_AP_REQUESTOR_SUBMISSION,
    AWAITING_AP_RESUBMISSION,
    checkAndPadd,
    enableEditForAp, Failed, Processed, Updated, cancelTimerInterval,
    currWithZeroDecimals,
    excelWrongFormatErr
} from '../helper'
import { getCommXchargeDCsks, getCommXchargeDLFA1, getCommXchargeDReportdashboardByRequestId2, getCrossChargeForm } from '../../graphql/queries'
import { API } from 'aws-amplify'
import ContextData from '../context/ContextData'
import { apsubmitCrossChargeForm } from '../../graphql/mutations'
import ExcelErrors from './ExcelErrors';

const APExcelUpload = () => {

    const [fileName, setFileName] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [percentCmplt, setPercentCmplt] = useState(0);
    const [processedData, setProcessedData] = useState(null);
    const [inProcess, setInProcess] = useState(true);
    const [showSpin, setShowSpin] = useState(false);

    const [hasValidData, setHasValidData] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");

    const contextValue = useContext(ContextData);
    const { currentUser, timerCtxt } = contextValue;

    const excelTableColumns = getExcelProcessColumns();

    // to clear all the leftout timeouts from a component
    useEffect(() => {

        const timerCancel = setInterval(() => {
            const { timerCtxt } = contextValue;
            for (let j = 0; j < timerCtxt.length; j++) {
                clearTimeout(timerCtxt[j])
            }
        }, cancelTimerInterval)

        return () => clearInterval(timerCancel)

    }, [timerCtxt])

    const handleExcelUpload = (e) => {

        const file_Name = e.target.files[0] && e.target.files[0].name;
        const fileToUpload = e.target.files[0];
        const fileExtension = file_Name?.split(".")?.pop();

        if (['xlsx'].includes(fileExtension.toLowerCase())) {
            setFileName(file_Name);
            setSelectedFile(fileToUpload);
            setInProcess(false);
        } else {
            Modal.error({
                className: 'info-modal',
                title: `Please upload file of type xlsx`,
                okButtonProps: { "className": "modal-btn" }
            });
        }
    }

    const groupBy = (arr, key) => {
        return arr.reduce((acc, cur) => {
            if (cur[key]) {
                acc[cur[key]] = [...acc[cur[key]] || [], cur];
            }
            return acc;

        }, {});
    }

    const groupByKeyValues = (arr, key) => {
        const a = groupBy(arr, key)
        return Object.values(a)
    }


    const hasAtMostTwoDecimals = (amount) => {
        // RegEx Patter to check if num have more than 2 decimals or not
        const regExMatch = amount.toString().match(/^(\d*\.{0,1}\d{0,2}$)/);

        // The match() returns null if the string gets NO match in the RegEx
        return (regExMatch === null) ? false : true;
    }

    const hasCurrencyFomatting = (amount) => {
        const amountStr = amount.toString();

        const decimalsCount = amountStr.split(".").length - 1;

        return (amountStr.includes(" ")
            ||
            amountStr.includes(",")
            ||
            decimalsCount > 1);
    }

    // const canCurrHaveDecimals = (curr) => {
    //     const currStr = curr.toString();

    //     return !currWithZeroDecimals.includes(currStr);
    // }



    // After "Process Btn" is clicked this method is called    
    const processAPExcelData = () => {

        setInProcess(true);
        setShowSpin(true);

        let fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFile);

        fileReader.onload = async (event) => {
            const data = event.target.result;
            const workBook = XLSX.read(data, { type: "binary" });

            const sheet1 = workBook.SheetNames[0]

            const rowObject = XLSX.utils.sheet_to_row_object_array(workBook.Sheets[sheet1]);

            const groupedData = groupByKeyValues(rowObject, "Portal_Request_No");
            console.log("groupedData", groupedData);

            // If excel sheet have wrong fromat
            if (groupedData?.length === 0) {
                setHasValidData(false);
                setErrorMsg(excelWrongFormatErr);
                setInProcess(false);
                setShowSpin(false);

                console.log("wrong excel", groupedData)
            }

            for (let i = 0; i < groupedData?.length; i++) {
                const apLineItems = [];

                //Inserting AP line items
                for (let j = 0; j < groupedData[i]?.length; j++) {

                    // If amount is formatted with commas or dots or spaces
                    if (hasCurrencyFomatting(groupedData[i][j]?.Amount)) {
                        setHasValidData(false);
                        setShowSpin(false);
                        console.log("amount formatted", groupedData[i][j]?.Amount);

                        break;
                    }
                    // If amount is 0
                    if (groupedData[i][j]?.Amount === 0) {
                        setHasValidData(false);
                        setShowSpin(false);
                        console.log("amount 0", groupedData[i][j]?.Amount);

                        break;
                    }
                    // Checking if amount has more than 2 decimal places
                    if (!hasAtMostTwoDecimals(parseFloat(groupedData[i][j]?.Amount))) {
                        setHasValidData(false);
                        setShowSpin(false);
                        console.log("amount more decimals", groupedData[i][j]?.Amount);

                        break;
                    }



                    // getting trading parner according to the acc entered in excel 
                    let tp = null;
                    if (apPostingKeys?.includes(groupedData[i][j]?.Posting_Key?.toString())) {
                        tp = await getTP(groupedData[i][j]?.Account, 'ap')
                    }

                    // getting profit center and tax jurisdiction if cost center is entered 
                    let prctr = null;
                    let txjcd = null;
                    const txcode = groupedData[i][j]?.Tax_Code?.toString()?.trim();
                    if (!(apPostingKeys?.includes(groupedData[i][j]?.Posting_Key?.toString()))) {
                        const costcntr = groupedData[i][j]?.CCTR;
                        if (costcntr) {
                            const cctrData = await getPrctrTaxJurisdiction(costcntr);
                            prctr = cctrData?.prctr;
                            // populating tax jurisdiction only if taxcode is entered
                            if (txcode) {
                                txjcd = cctrData?.txjcd;
                            }
                        } else {
                            prctr = groupedData[i][j]?.PRCTR;
                            if (txcode) {
                                txjcd = groupedData[i][j]?.Tax_Jurisdiction;
                            }
                        }

                    }

                    apLineItems.push(
                        {
                            id: addPaddingToNum((j + 1), 3),
                            item: addPaddingToNum((j + 1), 3),
                            postingKey: groupedData[i][j]?.Posting_Key?.toString(),
                            account: groupedData[i][j]?.Account?.toString()?.trim() && checkAndPadd(groupedData[i][j]?.Account, 10),
                            amount: Math.round((groupedData[i][j]?.Amount + Number.EPSILON) * 100) / 100,
                            taxCode: groupedData[i][j]?.Tax_Code?.toString()?.trim(),
                            taxJurisdiction: txjcd?.toString()?.trim() && checkAndPadd(txjcd?.toString()?.trim(), 10),
                            cctr: groupedData[i][j]?.CCTR?.toString()?.trim() && checkAndPadd(groupedData[i][j]?.CCTR, 10),
                            prctr: prctr?.toString()?.trim() && checkAndPadd(prctr?.toString()?.trim(), 10),
                            orderNumber: groupedData[i][j]?.Order_No?.toString()?.trim(),
                            lineItemAssignment: groupedData[i][j]?.Line_Item_Assignment?.toString()?.trim()?.slice(0, 18),
                            lineItemText: groupedData[i][j]?.Line_Item_Text?.toString()?.trim()?.slice(0, 50),
                            emg: groupedData[i][j]?.Ext_Mat_Grp?.toString()?.trim(),
                            product: groupedData[i][j]?.Product?.toString()?.trim(),
                            qty: groupedData[i][j]?.Qty,
                            uom: groupedData[i][j]?.UOM,
                            tradingPartner: tp
                        })
                }
                console.log("apLineItems", apLineItems)


                // Checking if all AP items are error free or not
                if (groupedData[0]?.length === apLineItems?.length) {

                    const reqId = parseInt(groupedData[i][0]?.Portal_Request_No)

                    const exeID = await getExecutionId(reqId)

                    const formData = await getFromData(exeID);
                    console.log("formData", formData)

                    if (exeID === 'error') {
                        const prcData = {
                            excelReqNo: reqId,
                            status: Failed,
                            reason: "Server error"
                        }
                        setProcessedData(prev => {
                            return [...(prev ? prev : []), prcData]
                        })
                    }
                    else if (!exeID) {
                        const prcData = {
                            excelReqNo: reqId,
                            status: Failed,
                            reason: "Provided portal/excel request number is incorrect"
                        }
                        setProcessedData(prev => {
                            return [...(prev ? prev : []), prcData]
                        })
                    }
                    else if (
                        [AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AWAITING_AP_RESUBMISSION, ADMIN_REQUESTED_AP_RESUBMISSION].includes(formData?.formStatus)
                        && enableEditForAp(formData?.formStatus, formData?.apRequestor, currentUser?.userID)
                    ) {
                        await updateRequest(formData, apLineItems);
                    }
                    else if (!(formData?.apRequestor?.toUpperCase() === currentUser?.userID?.toUpperCase())) {
                        const prcData = {
                            excelReqNo: formData.requestId,
                            status: Failed,
                            reason: "You don't have permission to update"
                        }
                        setProcessedData(prev => {
                            return [...(prev ? prev : []), prcData]
                        })
                    }
                    else if (![AWAITING_AP_REQUESTOR_SUBMISSION, AP_DRAFT, AWAITING_AP_RESUBMISSION, ADMIN_REQUESTED_AP_RESUBMISSION].includes(formData?.formStatus)) {
                        const prcData = {
                            id: formData.id,
                            requestId: formData.requestId,
                            excelReqNo: formData.requestId,
                            status: Failed,
                            reason: "Request is not in the state to update"
                        }
                        setProcessedData(prev => {
                            return [...(prev ? prev : []), prcData]
                        })
                    }

                    // setting completion percentage 
                    const cmpltPercent = ((i + 1) / groupedData?.length) * 100
                    setPercentCmplt(cmpltPercent.toFixed(2));
                }

            }


            setShowSpin(false);


        }

    }

    const updateRequest = async (formData, apItems) => {
        const payload = {
            ...formData,
            actionType: "AP_Save",
            formStatus: "AP_DRAFT",
            apLineItems: apItems,
            isadminAction: "N"
        }

        const res = await API
            .graphql({ query: apsubmitCrossChargeForm, variables: { input: payload } })
            .then((response) => {
                console.log("save response", response);

                const prcData = {
                    id: formData.id,
                    excelReqNo: formData.requestId,
                    requestId: formData.requestId,
                    status: Updated,
                    reason: ""
                }
                setProcessedData(prev => {
                    return [...(prev ? prev : []), prcData]
                })
                return response

            }).catch((err) => {
                console.log("save err", err);
                const prcData = {
                    id: formData.id,
                    requestId: formData.requestId,
                    excelReqNo: formData.requestId,
                    status: Failed,
                    reason: "Server error"
                }
                setProcessedData(prev => {
                    return [...(prev ? prev : []), prcData]
                })
                return null
            })

        return res
    }

    const getFromData = async (formID) => {
        const formData = await API
            .graphql({ query: getCrossChargeForm, variables: { id: formID } })
            .then((response) => {
                return response?.data?.getCrossChargeForm
            }).catch(err => {
                console.log("error in retriving form data", err)

            })
        return formData

    }

    const getExecutionId = async (reqNo) => {
        const exeID = await API
            .graphql({ query: getCommXchargeDReportdashboardByRequestId2, variables: { requestId: reqNo } })
            .then((response) => {
                return response?.data?.getCommXchargeDReportdashboardByRequestId2?.commXchargeDReportdashboards[0]?.id
            }).catch(err => {
                console.log("error in retriving form data", err)
                return "error"
            })
        return exeID
    }

    const getTP = async (acc) => {

        const tp = await API
            .graphql({ query: getCommXchargeDLFA1, variables: { id: checkAndPadd(acc, 10) } })
            .then(response => {
                console.log("trading partner response", response);
                const tp = response?.data?.getCommXchargeDLFA1?.vbund

                return tp

            }).catch(err => {
                console.log("err getting trading partner", err)
            })

        return tp
    }

    const getPrctrTaxJurisdiction = async (cctr) => {

        const cctrData = await API
            .graphql({ query: getCommXchargeDCsks, variables: { id: checkAndPadd(cctr, 10) } })
            .then(response => {
                console.log("cctr response", response);
                const cctrdata = response?.data?.getCommXchargeDCsks

                return {
                    prctr: cctrdata?.prctr,
                    txjcd: cctrdata?.txjcd
                }

            }).catch(err => {
                console.log("err getting trading partner", err)
            })

        return cctrData
    }

    const handleClear = () => {
        setFileName(null);
        setSelectedFile(null);
        setPercentCmplt(0);
        setProcessedData(null);
        setInProcess(false);

        setHasValidData(true);
        setErrorMsg("");
    }

    return (
        <div className='cc-excel-upload-cnt'>

            <Heading
                heading={<>
                    Upload Excel Template (AP)
                    <Tooltip title={`Allowed file type is xlsx`}>

                        <InfoCircleOutlined
                            className='cc-icons'
                            style={{ marginLeft: "10px" }}
                        />
                    </Tooltip>
                </>}
            >
            </Heading>

            <HorizontalLine />

            <div className='cc-upload-btn-cnt'>

                <div className='cc-upload-btn'>

                    <input type='file' id='excel-file' accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' style={{ display: 'none' }}
                        onChange={handleExcelUpload}
                        onClick={(e) => { e.target.value = '' }}
                    />
                    <label
                        htmlFor='excel-file'
                        className={fileName ? 'disabled-btn upld-btn' : 'ortho-btn upld-btn'}
                    >
                        {
                            !fileName &&
                            <FontAwesomeIcon icon={faUpload} className='cc-icons' style={{ marginRight: "10px", color: "white", opacity: "1" }} />
                        }
                        Upload a file
                    </label>

                    <div className='d-flex align-items-end'>
                        <span className='file-name-span'>{fileName}</span>
                        {
                            fileName &&
                            <FontAwesomeIcon icon={faXmark} onClick={handleClear} className='cc-icons  cc-icons-grey' style={{ marginLeft: "10px", paddingBottom: "3px" }} />
                        }
                    </div>
                </div>

                <div>
                    <Button className='ortho-btn prc-btn' onClick={processAPExcelData} disabled={inProcess || !fileName}>
                        Process Requests
                    </Button>
                </div>
            </div>

            {
                showSpin &&
                <div className='excel-spin'>
                    <Spin tip="Processing" size="small" />
                </div>
            }

            <Progress percent={percentCmplt} />


            {
                processedData?.length > 0 &&
                <Table
                    id="excel-table"
                    size="small"
                    className='excelTable'
                    style={{ width: "100%" }}
                    columns={excelTableColumns}
                    dataSource={processedData}
                    rowKey='excelReqNo'
                    bordered
                    // pagination={reviewData?.apLineItems?.length > 5 ? { pageSize: 5, size: 'small' } : false}  
                    pagination={false}
                    rowClassName={record => {
                        if ([Processed, Updated].includes(record.status)) {
                            return 'excel-success-row'
                        } else {
                            return 'excel-failed-row'
                        }
                    }}
                />
            }


            {/* Error Message */}
            {
                !hasValidData &&
                <>
                    <ExcelErrors errorMsg={errorMsg} />
                </>

            }


        </div>
    )
}

export default APExcelUpload