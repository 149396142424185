import React, { useContext, useEffect, useRef, useState } from 'react'
import { Table, message, Spin } from 'antd';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom'
import ContextData from '../context/ContextData';
import { listCommXchargeDReportdashboards } from '../../graphql/queries';
import { getDashboardColumns, getDashboardMobileColumns } from '../columns';
import Heading from '../HOC/Heading';
import HorizontalLine from '../HOC/HorizontalLine';
import { AWAITING_AR_APPROVAL, dashboardPollingInterval, formatDate, AWAITING_AR_RESUBMISSION, dashboardRetrivingLimit, DRAFT, showErrorMsg, AR_REJECTED, ADMIN_REQUESTED_AR_RESUBMISSION, getPaginationOptions } from '../helper';
import { createCrossChargerequest } from '../../graphql/mutations';

const DashboardTable = () => {

    const [formItems, setFormItems] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const contextValue = useContext(ContextData);
    const { currentUser, isAdmin, timerCtxt, setTimerCtxt, currencyFormat } = contextValue;
    const userID = currentUser?.userID?.toUpperCase()

    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();

    const timerRef= useRef({});
    timerRef.current = timerCtxt;

    useEffect(() => {

        const handleScreenSize = () => {
            setScreenSize(window.innerWidth)
        }

        window.addEventListener('resize', handleScreenSize);


        getDashboardRecords();
        getApDashboardRecords();
        getApproversReportRefresh();

        if(isAdmin){
            getAdminReport();
        }

        return () => {
            window.removeEventListener('resize', handleScreenSize);
            
            for(let i=0; i<timerRef.current?.length; i++){
                clearTimeout(timerRef.current[i]);                
            }
 
        };

    }, [isAdmin])

    
    const copyRequest = (exeID) => {
        const createInput = {
            actionType: "create",
            id: '123',
            arRequestor: currentUser?.userID?.toUpperCase(),
            arRequestorEmailAddress: currentUser?.userEmail,
            requestDate: formatDate()
        }

        setIsLoading(true);

        API
            .graphql({ query: createCrossChargerequest, variables: { input: createInput } })
            .then((response) => {
                // console.log("create response", response);
                navigate(`/request/${response?.data?.createCrossChargerequest?.id}`, { state: { copyId: exeID } } );
            }).catch((err) => {
                console.log("create err", err);
                setIsLoading(false);
                messageApi.open({
                    type: 'error',
                    content: 'This is an error while copying request.',
                    className: 'message-style',
                    duration: 5
                });
            })
    }


    const dashboardColumns = getDashboardColumns(currentUser?.userID?.toUpperCase(), copyRequest, showErrorMsg, currencyFormat);
    const dashboardMobileColumns = getDashboardMobileColumns(currentUser?.userID?.toUpperCase(), copyRequest, showErrorMsg, currencyFormat);

    const getDashboardRecords = () => {

        setFormItems(null);
        setIsLoading(true);

        const filter = {
            arRequestorId: { eq: currentUser?.userID?.toUpperCase() }
        }


        const getDashboardRecordsLoop = (nxtTkn) => {
            let variablesParams = { filter: filter, limit: dashboardRetrivingLimit };
            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    filter: filter,
                    limit: dashboardRetrivingLimit
                }
            }

            API
                .graphql({ query: listCommXchargeDReportdashboards, variables: variablesParams })
                .then(response => {
                    // console.log("dashboard response", response?.data?.listCommXchargeDReportdashboards);
                    const token = response?.data?.listCommXchargeDReportdashboards?.nextToken
                    const items = response?.data?.listCommXchargeDReportdashboards?.items

                    setFormItems(prev => {
                        const uniqueValues = [...new Map([...(prev ? prev : []), ...items]?.map(item => [item["requestId"], item])).values()];
                        return uniqueValues
                    });
                    setIsLoading(false);
                    if (token) {
                        getDashboardRecordsLoop(token);
                    }
                    if(items && !token){
                        // console.log("timer_ test1", timerCtxt, timerRef.current)
                        const timer_ = setTimeout(()=>{
                            getDashboardRecordsLoop("initial");
                        },dashboardPollingInterval);

                        setTimerCtxt((prev)=>{
                            prev[0] = timer_
                            return prev
                        })
                    }
                }).catch(err => {
                    console.log("err getting dashboard", err);
                    setIsLoading(false);
                    messageApi.open({
                        type: 'error',
                        content: 'This is an error while loading my request data.',
                        className: 'message-style',
                        duration: 5
                    });
                })

        }

        getDashboardRecordsLoop('initial');

    }

    const getDashboardRecordsRefresh = () => {

        // setFormItems(null);
        // setIsLoading(true);

        const filter = {
            arRequestorId: { eq: currentUser?.userID?.toUpperCase() }
        }


        const getDashboardRecordsLoop = (nxtTkn) => {
            let variablesParams = { filter: filter, limit: dashboardRetrivingLimit };
            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    filter: filter,
                    limit: dashboardRetrivingLimit
                }
            }

            API
                .graphql({ query: listCommXchargeDReportdashboards, variables: variablesParams })
                .then(response => {
                    // console.log("dashboard response", response?.data?.listCommXchargeDReportdashboards);
                    const token = response?.data?.listCommXchargeDReportdashboards?.nextToken
                    const items = response?.data?.listCommXchargeDReportdashboards?.items

                    setFormItems(prev => {
                        const uniqueValues = [...new Map([...(prev ? prev : []), ...items]?.map(item => [item["requestId"], item])).values()];
                        return uniqueValues
                    });
                    // setFormItems(items)
                    // setIsLoading(false);
                    if (token) {
                        getDashboardRecordsLoop(token);
                    }
                    if(items && !token){
                        const timer_ = setTimeout(()=>{
                            getDashboardRecordsLoop("initial");
                        },dashboardPollingInterval);
                        // console.log("timer inner",timer_)
                        setTimerCtxt((prev)=>{
                            prev[0] = timer_
                            return prev
                        })
                    }
                    
                }).catch(err => {
                    console.log("err getting dashboard", err);
                    // setIsLoading(false);
                    messageApi.open({
                        type: 'error',
                        content: 'This is an error while loading my request data.',
                        className: 'message-style',
                        duration: 5
                    });
                })

        }

        getDashboardRecordsLoop('initial');

    }


    // api for getting ap initaitor details 
    const getApDashboardRecords = () => {

        const filter = {
            apRequestorId: { eq: currentUser?.userID?.toUpperCase() }
        }


        const getApDashboardRecordsLoop = (nxtTkn) => {
            let variablesParams = { filter: filter, limit: dashboardRetrivingLimit };
            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    filter: filter,
                    limit: dashboardRetrivingLimit
                }
            }

            API
                .graphql({ query: listCommXchargeDReportdashboards, variables: variablesParams })
                .then(response => {
                    // console.log("dashboard response", response?.data?.listCommXchargeDReportdashboards);
                    const token = response?.data?.listCommXchargeDReportdashboards?.nextToken
                    const rawdata = response?.data?.listCommXchargeDReportdashboards?.items

                    const items = rawdata?.filter(itm => 
                            itm.status !== DRAFT && 
                            itm.status !== AWAITING_AR_RESUBMISSION &&
                            itm.status !== ADMIN_REQUESTED_AR_RESUBMISSION &&
                            itm.status !== AWAITING_AR_APPROVAL &&
                            itm.status !== AR_REJECTED 
                        )
                    
                    setFormItems(prev => {
                        const uniqueValues = [...new Map([...(prev ? prev : []), ...items]?.map(item => [item["requestId"], item])).values()];
                        return uniqueValues
                    });
                    if (token) {
                        getApDashboardRecordsLoop(token);
                    }

                    if(items && !token){
                        // console.log("timer_ test2", timerCtxt, timerRef.current)
                        const timer_ = setTimeout(()=>{
                            getApDashboardRecordsLoop("initial");
                        },dashboardPollingInterval);
                        setTimerCtxt((prev)=>{
                            prev[1] = timer_
                            return prev
                        })
                    }

                }).catch(err => {
                    console.log("err getting dashboard", err);
                    setIsLoading(false);
                    messageApi.open({
                        type: 'error',
                        content: 'This is an error while loading my request data.',
                        className: 'message-style',
                        duration: 5
                    });
                })

        }

        getApDashboardRecordsLoop('initial');

    }

    
    // api for getting approvers details
    const getApproversReportRefresh = () => {

        const filter = {
            // status:{ contains : "AWAITING" }
            status:{ ne : DRAFT }
        }

        const getApproversReportLoop = (nxtTkn) => {
            let variablesParams = {
                filter: filter, 
                limit: dashboardRetrivingLimit
            };
            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    filter: filter,
                    limit: dashboardRetrivingLimit
                }
            }

            API
                .graphql(
                    { query: listCommXchargeDReportdashboards, variables: variablesParams }
                )
                .then(response => {
                    // console.log("approver response", response?.data?.listCommXchargeDReportdashboards?.items);
                    const token = response?.data?.listCommXchargeDReportdashboards?.nextToken
                    const rawData = response?.data?.listCommXchargeDReportdashboards?.items
                    const filteredData = rawData?.filter(item =>
                        (item.arapprover1_id?.toUpperCase() === userID  ) ||
                        (item.arapprover2_id?.toUpperCase() === userID  ) ||
                        (item.arapprover3_id?.toUpperCase() === userID  ) ||
                        (item.arapprover4_id?.toUpperCase() === userID  ) ||
                        (item.arapprover5_id?.toUpperCase() === userID  ) ||

                        (item.apapprover1_id?.toUpperCase() === userID  ) ||
                        (item.apapprover2_id?.toUpperCase() === userID  ) ||
                        (item.apapprover3_id?.toUpperCase() === userID  ) ||
                        (item.apapprover4_id?.toUpperCase() === userID  ) ||
                        (item.apapprover5_id?.toUpperCase() === userID  )


                    )

                    // console.log("filteredData", filteredData);

                    

                    setFormItems(prev => {
                        const uniqueValues = [...new Map([...(prev ? prev : []), ...filteredData]?.map(item => [item["requestId"], item])).values()];
                        return uniqueValues
                    });

                    if (token) {
                        getApproversReportLoop(token);
                    }

                    if(rawData && !token){
                        // console.log("timer_ test3", timerCtxt, timerRef.current)
                        const timer_ = setTimeout(()=>{
                            getApproversReportLoop("initial");
                        },dashboardPollingInterval);
                        setTimerCtxt((prev)=>{
                            prev[2] = timer_
                            return prev
                        })
                    }
                }).catch(err => {
                    console.log("err getting approver res", err);
                    setIsLoading(false);
                    messageApi.open({
                        type: 'error',
                        content: 'This is an error while loading approvers data.',
                        className: 'message-style',
                        duration: 5
                    });
                })

        }

        getApproversReportLoop('initial');
    }

    // api to get all requests in waiting state for Admin 
    const getAdminReport = () => {

        const getAdminReportLoop = (nxtTkn) => {
            let variablesParams = {
                limit: dashboardRetrivingLimit
            };
            if (nxtTkn !== 'initial') {
                variablesParams = {
                    nextToken: nxtTkn,
                    limit: dashboardRetrivingLimit
                }
            }

            API
                .graphql(
                    { query: listCommXchargeDReportdashboards, variables: variablesParams }
                )
                .then(response => {
                    // console.log("approver response", response?.data?.listCommXchargeDReportdashboards?.items);
                    const token = response?.data?.listCommXchargeDReportdashboards?.nextToken
                    const rawData = response?.data?.listCommXchargeDReportdashboards?.items                    

                    const filteredData = rawData?.filter(itm => 
                        itm.status !== DRAFT
                    )

                    // const filteredData = rawData

                    setFormItems(prev => {
                        const uniqueValues = [...new Map([...(prev ? prev : []), ...filteredData]?.map(item => [item["requestId"], item])).values()];
                        return uniqueValues
                    });

                    if (token) {
                        getAdminReportLoop(token);
                    }

                    if(rawData && !token){
                        // console.log("timer_ test4", timerCtxt, timerRef.current)
                        const timer_ = setTimeout(()=>{
                            getAdminReportLoop("initial");
                        },dashboardPollingInterval);
                        setTimerCtxt((prev)=>{
                            prev[3] = timer_
                            return prev
                        })
                    }
                }).catch(err => {
                    console.log("err getting admin res", err);
                    setIsLoading(false);
                    messageApi.open({
                        type: 'error',
                        content: 'This is an error while loading approvers data.',
                        className: 'message-style',
                        duration: 2
                    });
                })

        }

        getAdminReportLoop('initial');
    }



    return (
        <>
          
            {contextHolder}

            <Heading 
                heading = 'My Requests'
                style = {{marginTop:"30px"}}
            >
            </Heading>
            

            <HorizontalLine />

            <Spin spinning={isLoading}>

                <Table
                    id="dashboard-table"
                    size="small"
                    className={screenSize > 767 ? 'dashboardTable' : 'dashboardTable-mobile'}
                    columns={screenSize > 767 ? dashboardColumns : dashboardMobileColumns}
                    dataSource={formItems}
                    rowKey='id'
                    pagination={getPaginationOptions(formItems?.length, 10)}
                />
            </Spin>
        </>
    )
}

export default DashboardTable