import React, { useEffect, useState } from 'react'
import { Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'

import Heading from '../HOC/Heading'
import HorizontalLine from '../HOC/HorizontalLine'
import TableInfoModal from './TableInfoModal'
import { allowAR, DeleteItems, formDisable, getPaginationOptions } from '../helper'
import { getArApColumns } from '../columns'


const ARTable = (props) => {
    // console.log('ar props', props);
    const { formData } = props;

    const [ARItems, setARItems] = useState([]);
    const [editItem, setEditItem] = useState(null);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [open, setOpen] = useState(false);

    // const formDisableCondition = formDisable(props?.formStatus)
    const formDisableCondition = !(allowAR(props?.formStatus, formData?.arRequestor, props?.currentUser))

    useEffect(() => {
        props.getARItems(ARItems)

    }, [ARItems])

    useEffect(() => {
        if (props.ARItemsUpdate) {
            setARItems(props.ARItemsUpdate);
        }
    }, [props.ARItemsUpdate])

    const arColumns = [
        ...getArApColumns(props?.currency, props?.currencyFormat),
        {
            title: 'Action',

            render: (text, record) => {
                return (
                    <div className='action-column'>
                        {
                            !formDisableCondition &&
                            <FontAwesomeIcon icon={faPenToSquare}
                                title='Edit item'
                                className='cc-icons'
                                onClick={() => {
                                    setEditItem(record);
                                    setOpen(true);
                                }}
                            />
                        }

                    </div>

                )

            }
        }
    ]


    const onSelectChange = (newSelectedRowKeys) => {
        // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        // getCheckboxProps: (record) => {
        //     return{
        //         disabled:true
        //     }
        // }
    };







    return (
        <div>
            <Heading
                heading="AR Information"
            >
                {
                    !formDisableCondition &&
                    <div className='cc-item-add-dlt-cnt'>
                        <FontAwesomeIcon icon={faPlus}
                            title='Add item'
                            className='cc-icons cc-add-icon'
                            onClick={() => setOpen(true)}
                        />

                        <FontAwesomeIcon icon={faMinus}
                            title='Delete selected items'
                            className='cc-icons cc-minus-icon'
                            onClick={() => DeleteItems(ARItems, selectedRowKeys, setARItems)}
                        />
                    </div>
                }
            </Heading>
            <HorizontalLine />





            <Table
                id="ar-table"
                size="small"
                className='arTable'
                style={{ width: "100%" }}
                // columns={screenSize > 767 ? orderItemsColumns : orderMobileItemsColumns}
                columns={arColumns}
                dataSource={ARItems}
                rowKey='item'
                bordered
                rowSelection={rowSelection}
                pagination={getPaginationOptions(ARItems?.length, 10)}
                scroll={{ x: 1200 }}
            />

            {
                open &&
                <TableInfoModal
                    requestType={props?.requestType}
                    currency={props?.currency}
                    currencyDec={props?.currencyDec}
                    companyCode={props?.companyCode}
                    // country = {props?.country}
                    fieldOptions={props?.fieldOptions}
                    receivingCC={props?.receivingCC}
                    open={open}
                    close={() => setOpen(false)}
                    lineItems={ARItems}
                    setlineItems={setARItems}
                    editItem={editItem}
                    setEditItem={setEditItem}
                    currentUser='ar'
                    sap={props?.sap}
                />
            }

        </div>
    )
}

export default ARTable