import React, { useState } from 'react'
import { Form, Input, Modal } from 'antd';
import Heading from '../HOC/Heading';
import { AdminReject, AdminRejectEnd } from '../helper';

const { TextArea } = Input;

const CommentModal = (props) => {

    const [form] = Form.useForm();

    const [higherComments, setHigherComments] = useState('');

    const handleHigherComments = (e) => {
        // console.log("higher cmnts", e.target.value)
        setHigherComments(e.target.value)
    }

    const handleModalSubmit = ()=>{
        form.validateFields()
            .then(() => {
                props.onSubmit(higherComments.trim(), props?.actionType);
                props.closeComment();
            }).catch((errorInfo) => {
                console.log("errorInfo", errorInfo)
            })
    }

    return (
        <div>
            <Modal
                className='cc-comment-modal'
                title={<Heading 
                heading={(props?.actionType === 'Additional Infomation') ? 
                                                `Request ${props?.actionType}`:
                                                ( [AdminReject, AdminRejectEnd].includes(props?.actionType) ) ? 
                                                `Reject Request`: `${props?.actionType} Request`} 

                        />}
                centered
                open={props?.openComment}
                okText={props?.actionType === 'Additional Infomation' ? 'Request Information' : 
                [AdminReject, AdminRejectEnd].includes(props?.actionType) ? 'Reject' : props?.actionType}
                cancelButtonProps={{ "className": "ortho-btn" }}
                okButtonProps={{ "className": "ortho-btn" }}
                onOk = {handleModalSubmit}
                onCancel={() => props.closeComment()}
                width={800}
            >

                <Form
                    form={form}
                >
                    <Form.Item
                        label="Add comments"
                        name="higherComments"
                        rules={!(props?.actionType === 'Approve') ? [
                            {
                                required: true,
                                message: 'Please add comments',
                            },
                        ] : []}
                    >
                        <TextArea
                            value={higherComments}
                            onChange={handleHigherComments}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default CommentModal