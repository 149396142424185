import { API } from "aws-amplify";
import { getCommXchargeDS4Migratedcompanycode } from "../graphql/queries";

export const getSAPType = async (companyCode) => {
    const variablesParams = JSON.stringify({
        id: companyCode
    });

    const sapType = await API
        .graphql({ query: getCommXchargeDS4Migratedcompanycode, variables: variablesParams })
        .then((response) => {
            const data = response?.data?.getCommXchargeDS4Migratedcompanycode;

            // console.log("get sap type resp of " + companyCode + " is : ", data);

            if (data) {
                return "s4";
            }
            else {
                return "fit or mars";
            }
        })
        .catch((err) => {
            console.log("fetching s4 or not error: ", err);
            return "Error in Fetching data";
        });


    return sapType;
}


export const filterAndSort = (prev, refinedOpt) => {
    if (refinedOpt) {
        // Using Set to eliminate duplicates
        const uniqueVals = new Set();

        const sorted = [...(prev ? prev : []), ...refinedOpt]
            .filter((item) => {
                if (uniqueVals?.has(item.value)) {
                    return false;
                }

                uniqueVals.add(item.value);

                return true;
            });

        // Sorting the values in Ascending order
        sorted.sort((a, b) => a.value?.localeCompare(b.value, undefined, {
            numeric: true,
            sensitivity: 'base'
        }));

        return sorted;
    }
}