import React, { useState, useEffect } from "react";
import { Auth, API, Hub } from 'aws-amplify';

import 'antd/dist/antd.min.css'
import './css/common.css';
import RequestForm from "./components/forms/RequestForm";
import ReviewForm from "./components/forms/ReviewForm";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./components/forms/Dashboard";
import ContextData from "./components/context/ContextData";
import { getCommXchargeDUsr01, listCommDXchargeTwews, listCommXchargeDMaras, listCommXchargeDT006AS, listCommXchargeDTcurcs, listCommXchargeDTradingpartners } from "./graphql/queries";
import { descSeparator } from "./components/helper";
import Header from "./components/Header";
import { Spin } from "antd";
import ReportsDashboard from "./components/reportComponents/ReportsDashboard";
import Reports from "./components/reportComponents/Reports";
import ExcelUpload from "./components/dashboardComponents/ExcelUpload";
import APExcelUpload from "./components/dashboardComponents/APExcelUpload";

function App() {

  const button = React.createRef();

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isNotEmployee, setIsNotEmployee] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [extMatGroupOption, setExtMatGroupOption] = useState(null);
  const [productOption, setProductOption] = useState(null);
  const [UOMOption, setUOMOption] = useState(null);

  const [currencyOption, setCurrencyOption] = useState(null);
  const [marsTP, setMarsTP] = useState(null);

  const [timerCtxt, setTimerCtxt] = useState(['initial', 'initial', 'initial', 'initial']);


  const [currencyFormat, setCurrencyFormat] = useState("en-US");


  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "customOAuthState":
          window.location.href = data;
          break;
        default:
          return;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(user => {
        const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups'] || [];
        const userID = user?.attributes['custom:AccountName'].split('@')[0];
        const userEmail = user?.attributes?.email
        const userType = user?.attributes['custom:UserType']

        setCurrentUser({
          userID: userID,
          userEmail: userEmail
        })

        setIsAuthorized(true);
        if (userType !== 'E') {
          setIsNotEmployee(true)
        }

        if (cognitoGroups?.includes(process.env.REACT_APP_Admin_Group)) {
          setIsAdmin(true)
        }

        getExtMatGroup();
        getProductMatNum();
        getUOM();
        getCurrency();
        getMarsTP();




      })
      .catch((e) => {
        console.log("Error in Authentication");
        console.error(e);
        button.current.click();
      });


    return unsubscribe;
  }, [])



  const getExtMatGroup = () => {
    // const getExtMatGroupLoop = (nxtTkn) => {
    //   let variablesParams = { limit: 1000 }; //10000
    //   if (nxtTkn !== 'initial') {
    //     variablesParams = {
    //       nextToken: nxtTkn,
    //       limit: 1000 //10000
    //     }
    //   }

    //   API
    //     .graphql({ query: listCommDXchargeTwews, variables: variablesParams })
    //     .then(response => {
    //       console.log("ext mat grup response", response?.data?.listCommDXchargeTwews);
    //       const token = response?.data?.listCommDXchargeTwews?.nextToken
    //       const refinedOpt = response?.data?.listCommDXchargeTwews?.items?.map(d => {
    //         if (d.ewbez?.trim()) {
    //           return {
    //             value: d.extwg,
    //             label: d.extwg + descSeparator + d.ewbez
    //           }
    //         } else {
    //           return {
    //             value: d.extwg,
    //             label: d.extwg
    //           }
    //         }

    //       })

    //       setExtMatGroupOption(prev => {
    //         const sorted = [...(prev ? prev : []), ...refinedOpt]
    //         sorted.sort((a, b) => a.value?.localeCompare(b.value, undefined, {
    //           numeric: true,
    //           sensitivity: 'base'
    //         }));
    //         return sorted
    //       });

    //       if (token) {
    //         getExtMatGroupLoop(token);
    //       }

    //     }).catch(err => {
    //       console.log("err getting ext mat grup", err)
    //     })

    // }

    // getExtMatGroupLoop('initial');

  }


  const getProductMatNum = () => {

    // const getProductMatNumLoop = (nxtTkn) => {
    //   let variablesParams = { limit: 5000 }; //50000
    //   if (nxtTkn !== 'initial') {
    //     variablesParams = {
    //       nextToken: nxtTkn,
    //       limit: 5000 //50000
    //     }
    //   }

    //   API
    //     .graphql({ query: listCommXchargeDMaras, variables: variablesParams })
    //     .then(response => {
    //       console.log("product mat num response", response?.data?.listCommXchargeDMaras);
    //       const token = response?.data?.listCommXchargeDMaras?.nextToken
    //       const refinedOpt = response?.data?.listCommXchargeDMaras?.items?.map(d => {
    //         if (d.maktx) {
    //           return {
    //             value: d.matnr,
    //             label: d.matnr + descSeparator + d.maktx
    //           }
    //         } else {
    //           return {
    //             value: d.matnr,
    //             label: d.matnr
    //           }
    //         }

    //       })

    //       // setProductOption(refinedOpt);
    //       setProductOption(prev => {
    //         const sorted = [...(prev ? prev : []), ...refinedOpt]
    //         sorted.sort((a, b) => a.value?.localeCompare(b.value, undefined, {
    //           numeric: true,
    //           sensitivity: 'base'
    //         }));
    //         return sorted
    //       });

    //       if (token) {
    //         getProductMatNumLoop(token);
    //       }

    //     }).catch(err => {
    //       console.log("err getting product mat num", err)
    //     })

    // }

    // getProductMatNumLoop('initial');

  }

  const getUOM = () => {

    // const getUOMLoop = (nxtTkn) => {
    //   let variablesParams = { limit: 500 }; //5000
    //   if (nxtTkn !== 'initial') {
    //     variablesParams = {
    //       nextToken: nxtTkn,
    //       limit: 500 //5000
    //     }
    //   }

    //   API
    //     .graphql({ query: listCommXchargeDT006AS, variables: variablesParams })
    //     .then(response => {
    //       console.log("uom response", response?.data?.listCommXchargeDT006AS);
    //       const token = response?.data?.listCommXchargeDT006AS?.nextToken
    //       const refinedOpt = response?.data?.listCommXchargeDT006AS?.items?.map(d => {
    //         return {
    //           value: d.msehi,
    //           label: d.msehi
    //         }
    //       })



    //       setUOMOption(prev => {
    //         const uniqueValues = [...new Map([...(prev ? prev : []), ...refinedOpt]?.map(item => [item["value"], item])).values()];
    //         uniqueValues.sort((a, b) => a.value?.localeCompare(b.value, undefined, {
    //           numeric: false,
    //           sensitivity: 'base'
    //         }));
    //         return uniqueValues
    //       });

    //       if (token) {
    //         getUOMLoop(token);
    //       }

    //     }).catch(err => {
    //       console.log("err getting uom", err)
    //     })

    // }

    // getUOMLoop('initial');

  }

  const getCurrency = () => {

    const getCurrencyLoop = (nxtTkn) => {
      let variablesParams = { limit: 500 };
      if (nxtTkn !== 'initial') {
        variablesParams = {
          nextToken: nxtTkn,
          limit: 500
        }
      }
      API
        .graphql({ query: listCommXchargeDTcurcs, variables: variablesParams })
        .then(response => {
          const token = response?.data?.listCommXchargeDTcurcs?.nextToken
          const refinedOpt = response?.data?.listCommXchargeDTcurcs?.items?.map(d => {
            return {
              value: d.waers,
              label: d.waers
            }
          })
          setCurrencyOption(prev => {
            const sorted = [...(prev ? prev : []), ...refinedOpt]
            sorted.sort((a, b) => a.value?.localeCompare(b.value));
            return sorted
          });
          if (token) {
            getCurrencyLoop(token);
          }


        })
        .catch(error => {
          console.log("error in currency", error);
        });
    }

    getCurrencyLoop('initial');

  }

  const getMarsTP = () => {

    const getMarsTPLoop = (nxtTkn) => {
      let variablesParams = { limit: 500 };
      if (nxtTkn !== 'initial') {
        variablesParams = {
          nextToken: nxtTkn,
          limit: 500
        }
      }
      API
        .graphql({ query: listCommXchargeDTradingpartners, variables: variablesParams })
        .then(response => {
          const token = response?.data?.listCommXchargeDTradingpartners?.nextToken
          // console.log("mars tp", response?.data?.listCommXchargeDTradingpartners)

          const rawData = response?.data?.listCommXchargeDTradingpartners?.items
          const refindeData = {};
          for (let i = 0; i < rawData.length; i++) {
            refindeData[rawData[i]?.marscode] = rawData[i]?.tradingpartner
          }

          setMarsTP(prev => {
            return { ...(prev ? prev : {}), ...refindeData }
          });

          if (token) {
            getMarsTPLoop(token);
          }


        })
        .catch(error => {
          console.log("error in mars tp", error);
        });
    }

    getMarsTPLoop('initial');

  }

  // Currency:Format - Setter Function
  const setUserCurrencyFormat = () => {
    // X        -> 1,234,567.89
    // "space"  -> 1.234.567,89
    // Y        -> 1 234 567,89

    if (currentUser?.userID) {
      API
        .graphql({ query: getCommXchargeDUsr01, variables: { id: currentUser?.userID?.toUpperCase() } })
        .then((response) => {
          console.log("currency format response: ", response);
          
          setCurrencyFormat(
            (response?.data?.getCommXchargeDUsr01?.dcpfm === "X") ? "en-US" : "de-DE"
          );
        })
        .catch((err) => {
          console.log("Fetching currency details Error: ", err);
        });
    }
  }


  // console.log("admin is", isAdmin);

  // Currency:Format - Setter CALL
  setUserCurrencyFormat();


  return (
    <div>
      {
        isNotEmployee ?
          <h1>Not an employee</h1>
          :
          isAuthorized ?
            <ContextData.Provider value={{ productOption, UOMOption, extMatGroupOption, currentUser, currencyOption, isAdmin, marsTP, timerCtxt, setTimerCtxt, currencyFormat }}>
              <Spin spinning={isLoading}>
                <BrowserRouter>

                  <Header
                    setIsLoading={setIsLoading}
                  />

                  <Routes>
                    <Route exact path="/" element={
                      <Dashboard />
                    }
                    />
                    <Route exact path="/request/:formID" element={
                      <RequestForm />
                    }
                    />
                    <Route exact path="/review/:formID" element={
                      <ReviewForm />
                    }
                    />

                    <Route exact path="/ar/excelupload" element={
                      <ExcelUpload />
                    }
                    />

                    <Route exact path="/ap/excelupload" element={
                      <APExcelUpload />
                    }
                    />

                    <Route exact path="/ReportsDashboard" element={
                      <ReportsDashboard />
                    }
                    />

                    <Route exact path="/Reports" element={
                      <Reports />
                    }
                    />
                  </Routes>
                </BrowserRouter>
              </Spin>
            </ContextData.Provider>

            :
            <div>
              <div><h2>Redirecting to login page. Please wait...</h2></div>
              <button
                ref={button}
                style={{ display: 'none' }}
                onClick={() =>
                  Auth.federatedSignIn({ "provider": process.env.REACT_APP_Auth_FederatedSignIn_Provider, "customState": window.location.href })
                    .then(result => {
                      // console.log("Authentication federatedSignIn, data- " + JSON.stringify(result));
                    })
                    .catch(err => {
                      console.error("Authentication federatedSignIn, error- " + err);
                    })
                }
              >
                Login
              </button>
            </div>
      }


    </div>
  );
}

export default App;
