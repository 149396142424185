/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminactionperformerCrossChargeForm = /* GraphQL */ `
  mutation AdminactionperformerCrossChargeForm($input: CrossChargeFormInput!) {
    adminactionperformerCrossChargeForm(input: $input) {
      actionAwaiting
      actionAwaitingEmailAddr
      actionType
      adminActionLvl_AP
      adminActionLvl_AR
      adminComments
      adminEmailAddress
      adminUserId
      adminsubmitedDate
      apApprovers {
        actualAPApprovers
        apRequestor
        apapprover1_comments
        apapprover1_dateOfApproval
        apapprover1_dateOfEntry
        apapprover1_emailAddr
        apapprover1_id
        apapprover1_isdelegator
        apapprover1_lvl
        apapprover1_mgremailaddress
        apapprover1_mgrid
        apapprover1_name
        apapprover1_status
        apapprover2_comments
        apapprover2_dateOfApproval
        apapprover2_dateOfEntry
        apapprover2_emailAddr
        apapprover2_id
        apapprover2_isdelegator
        apapprover2_lvl
        apapprover2_mgremailaddress
        apapprover2_mgrid
        apapprover2_name
        apapprover2_status
        apapprover3_comments
        apapprover3_dateOfApproval
        apapprover3_dateOfEntry
        apapprover3_emailAddr
        apapprover3_id
        apapprover3_isdelegator
        apapprover3_lvl
        apapprover3_mgremailaddress
        apapprover3_mgrid
        apapprover3_name
        apapprover3_status
        apapprover4_comments
        apapprover4_dateOfApproval
        apapprover4_dateOfEntry
        apapprover4_emailAddr
        apapprover4_id
        apapprover4_isdelegator
        apapprover4_lvl
        apapprover4_mgremailaddress
        apapprover4_mgrid
        apapprover4_name
        apapprover4_status
        apapprover5_comments
        apapprover5_dateOfApproval
        apapprover5_dateOfEntry
        apapprover5_emailAddr
        apapprover5_id
        apapprover5_isdelegator
        apapprover5_lvl
        apapprover5_mgremailaddress
        apapprover5_mgrid
        apapprover5_name
        apapprover5_status
        dType
        existAPApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      apLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      apRequestSubmittedDate
      apRequestor
      apRequestorEmailAddress
      apRequestor_comments
      apRequestor_dateOfEntry
      apRequestor_mgremailaddress
      apRequestor_mgrid
      apSAPDocNo
      ap_doc_created_at
      ap_doc_created_by
      ap_doc_created_on
      ap_pay_term
      ap_pay_term_desc
      ap_posting_period
      ap_posting_year
      apapSAPErrorMessage
      apresult
      arApprovers {
        actualARApprovers
        arRequestor
        arapprover1_comments
        arapprover1_dateOfApproval
        arapprover1_dateOfEntry
        arapprover1_emailAddr
        arapprover1_id
        arapprover1_isdelegator
        arapprover1_lvl
        arapprover1_mgremailaddress
        arapprover1_mgrid
        arapprover1_name
        arapprover1_status
        arapprover2_comments
        arapprover2_dateOfApproval
        arapprover2_dateOfEntry
        arapprover2_emailAddr
        arapprover2_id
        arapprover2_isdelegator
        arapprover2_lvl
        arapprover2_mgremailaddress
        arapprover2_mgrid
        arapprover2_name
        arapprover2_status
        arapprover3_comments
        arapprover3_dateOfApproval
        arapprover3_dateOfEntry
        arapprover3_emailAddr
        arapprover3_id
        arapprover3_isdelegator
        arapprover3_lvl
        arapprover3_mgremailaddress
        arapprover3_mgrid
        arapprover3_name
        arapprover3_status
        arapprover4_comments
        arapprover4_dateOfApproval
        arapprover4_dateOfEntry
        arapprover4_emailAddr
        arapprover4_id
        arapprover4_isdelegator
        arapprover4_lvl
        arapprover4_mgremailaddress
        arapprover4_mgrid
        arapprover4_name
        arapprover4_status
        arapprover5_comments
        arapprover5_dateOfApproval
        arapprover5_dateOfEntry
        arapprover5_emailAddr
        arapprover5_id
        arapprover5_isdelegator
        arapprover5_lvl
        arapprover5_mgremailaddress
        arapprover5_mgrid
        arapprover5_name
        arapprover5_status
        dType
        existARApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      arLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      arRequestSubmittedDate
      arRequestor
      arRequestorEmailAddress
      arRequestor_comments
      arSAPDocNo
      arSAPErrorMessage
      ar_doc_created_at
      ar_doc_created_by
      ar_doc_created_on
      ar_pay_term
      ar_pay_term_desc
      ar_posting_period
      ar_posting_year
      arresult
      attachments {
        createdAt
        createdBy
        fileName
        id
        index
        
      }
      commentHistoryItems {
        comment
        mailId
        time
        
      }
      currAPApproverLVL
      currARApproverLVL
      currency
      dig_sign
      exchangeRate
      executionID
      formStatus
      id
      invoiceDate
      invoiceNo
      isadminAction
      postingDate
      receivingCompanyCode
      recvBusinessPlace
      recvCountryCode
      recvCurrAmt
      requestCompletionDate
      requestDate
      requestDescription
      requestId
      requestType
      sapfailureMessage
      sendBusinessPlace
      sendCountryCode
      sendCurrAmt
      sendingCompanyCode
      stateName
      totalAPApproverLVL
      totalARApproverLVL
      totalAmt
      uniq_doc
      
    }
  }
`;
export const apactionperformerCrossChargeForm = /* GraphQL */ `
  mutation ApactionperformerCrossChargeForm($input: CrossChargeFormInput!) {
    apactionperformerCrossChargeForm(input: $input) {
      actionAwaiting
      actionAwaitingEmailAddr
      actionType
      adminActionLvl_AP
      adminActionLvl_AR
      adminComments
      adminEmailAddress
      adminUserId
      adminsubmitedDate
      apApprovers {
        actualAPApprovers
        apRequestor
        apapprover1_comments
        apapprover1_dateOfApproval
        apapprover1_dateOfEntry
        apapprover1_emailAddr
        apapprover1_id
        apapprover1_isdelegator
        apapprover1_lvl
        apapprover1_mgremailaddress
        apapprover1_mgrid
        apapprover1_name
        apapprover1_status
        apapprover2_comments
        apapprover2_dateOfApproval
        apapprover2_dateOfEntry
        apapprover2_emailAddr
        apapprover2_id
        apapprover2_isdelegator
        apapprover2_lvl
        apapprover2_mgremailaddress
        apapprover2_mgrid
        apapprover2_name
        apapprover2_status
        apapprover3_comments
        apapprover3_dateOfApproval
        apapprover3_dateOfEntry
        apapprover3_emailAddr
        apapprover3_id
        apapprover3_isdelegator
        apapprover3_lvl
        apapprover3_mgremailaddress
        apapprover3_mgrid
        apapprover3_name
        apapprover3_status
        apapprover4_comments
        apapprover4_dateOfApproval
        apapprover4_dateOfEntry
        apapprover4_emailAddr
        apapprover4_id
        apapprover4_isdelegator
        apapprover4_lvl
        apapprover4_mgremailaddress
        apapprover4_mgrid
        apapprover4_name
        apapprover4_status
        apapprover5_comments
        apapprover5_dateOfApproval
        apapprover5_dateOfEntry
        apapprover5_emailAddr
        apapprover5_id
        apapprover5_isdelegator
        apapprover5_lvl
        apapprover5_mgremailaddress
        apapprover5_mgrid
        apapprover5_name
        apapprover5_status
        dType
        existAPApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      apLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      apRequestSubmittedDate
      apRequestor
      apRequestorEmailAddress
      apRequestor_comments
      apRequestor_dateOfEntry
      apRequestor_mgremailaddress
      apRequestor_mgrid
      apSAPDocNo
      ap_doc_created_at
      ap_doc_created_by
      ap_doc_created_on
      ap_pay_term
      ap_pay_term_desc
      ap_posting_period
      ap_posting_year
      apapSAPErrorMessage
      apresult
      arApprovers {
        actualARApprovers
        arRequestor
        arapprover1_comments
        arapprover1_dateOfApproval
        arapprover1_dateOfEntry
        arapprover1_emailAddr
        arapprover1_id
        arapprover1_isdelegator
        arapprover1_lvl
        arapprover1_mgremailaddress
        arapprover1_mgrid
        arapprover1_name
        arapprover1_status
        arapprover2_comments
        arapprover2_dateOfApproval
        arapprover2_dateOfEntry
        arapprover2_emailAddr
        arapprover2_id
        arapprover2_isdelegator
        arapprover2_lvl
        arapprover2_mgremailaddress
        arapprover2_mgrid
        arapprover2_name
        arapprover2_status
        arapprover3_comments
        arapprover3_dateOfApproval
        arapprover3_dateOfEntry
        arapprover3_emailAddr
        arapprover3_id
        arapprover3_isdelegator
        arapprover3_lvl
        arapprover3_mgremailaddress
        arapprover3_mgrid
        arapprover3_name
        arapprover3_status
        arapprover4_comments
        arapprover4_dateOfApproval
        arapprover4_dateOfEntry
        arapprover4_emailAddr
        arapprover4_id
        arapprover4_isdelegator
        arapprover4_lvl
        arapprover4_mgremailaddress
        arapprover4_mgrid
        arapprover4_name
        arapprover4_status
        arapprover5_comments
        arapprover5_dateOfApproval
        arapprover5_dateOfEntry
        arapprover5_emailAddr
        arapprover5_id
        arapprover5_isdelegator
        arapprover5_lvl
        arapprover5_mgremailaddress
        arapprover5_mgrid
        arapprover5_name
        arapprover5_status
        dType
        existARApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      arLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      arRequestSubmittedDate
      arRequestor
      arRequestorEmailAddress
      arRequestor_comments
      arSAPDocNo
      arSAPErrorMessage
      ar_doc_created_at
      ar_doc_created_by
      ar_doc_created_on
      ar_pay_term
      ar_pay_term_desc
      ar_posting_period
      ar_posting_year
      arresult
      attachments {
        createdAt
        createdBy
        fileName
        id
        index
        
      }
      commentHistoryItems {
        comment
        mailId
        time
        
      }
      currAPApproverLVL
      currARApproverLVL
      currency
      dig_sign
      exchangeRate
      executionID
      formStatus
      id
      invoiceDate
      invoiceNo
      isadminAction
      postingDate
      receivingCompanyCode
      recvBusinessPlace
      recvCountryCode
      recvCurrAmt
      requestCompletionDate
      requestDate
      requestDescription
      requestId
      requestType
      sapfailureMessage
      sendBusinessPlace
      sendCountryCode
      sendCurrAmt
      sendingCompanyCode
      stateName
      totalAPApproverLVL
      totalARApproverLVL
      totalAmt
      uniq_doc
      
    }
  }
`;
export const apsubmitCrossChargeForm = /* GraphQL */ `
  mutation ApsubmitCrossChargeForm($input: CrossChargeFormInput!) {
    apsubmitCrossChargeForm(input: $input) {
      actionAwaiting
      actionAwaitingEmailAddr
      actionType
      adminActionLvl_AP
      adminActionLvl_AR
      adminComments
      adminEmailAddress
      adminUserId
      adminsubmitedDate
      apApprovers {
        actualAPApprovers
        apRequestor
        apapprover1_comments
        apapprover1_dateOfApproval
        apapprover1_dateOfEntry
        apapprover1_emailAddr
        apapprover1_id
        apapprover1_isdelegator
        apapprover1_lvl
        apapprover1_mgremailaddress
        apapprover1_mgrid
        apapprover1_name
        apapprover1_status
        apapprover2_comments
        apapprover2_dateOfApproval
        apapprover2_dateOfEntry
        apapprover2_emailAddr
        apapprover2_id
        apapprover2_isdelegator
        apapprover2_lvl
        apapprover2_mgremailaddress
        apapprover2_mgrid
        apapprover2_name
        apapprover2_status
        apapprover3_comments
        apapprover3_dateOfApproval
        apapprover3_dateOfEntry
        apapprover3_emailAddr
        apapprover3_id
        apapprover3_isdelegator
        apapprover3_lvl
        apapprover3_mgremailaddress
        apapprover3_mgrid
        apapprover3_name
        apapprover3_status
        apapprover4_comments
        apapprover4_dateOfApproval
        apapprover4_dateOfEntry
        apapprover4_emailAddr
        apapprover4_id
        apapprover4_isdelegator
        apapprover4_lvl
        apapprover4_mgremailaddress
        apapprover4_mgrid
        apapprover4_name
        apapprover4_status
        apapprover5_comments
        apapprover5_dateOfApproval
        apapprover5_dateOfEntry
        apapprover5_emailAddr
        apapprover5_id
        apapprover5_isdelegator
        apapprover5_lvl
        apapprover5_mgremailaddress
        apapprover5_mgrid
        apapprover5_name
        apapprover5_status
        dType
        existAPApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      apLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      apRequestSubmittedDate
      apRequestor
      apRequestorEmailAddress
      apRequestor_comments
      apRequestor_dateOfEntry
      apRequestor_mgremailaddress
      apRequestor_mgrid
      apSAPDocNo
      ap_doc_created_at
      ap_doc_created_by
      ap_doc_created_on
      ap_pay_term
      ap_pay_term_desc
      ap_posting_period
      ap_posting_year
      apapSAPErrorMessage
      apresult
      arApprovers {
        actualARApprovers
        arRequestor
        arapprover1_comments
        arapprover1_dateOfApproval
        arapprover1_dateOfEntry
        arapprover1_emailAddr
        arapprover1_id
        arapprover1_isdelegator
        arapprover1_lvl
        arapprover1_mgremailaddress
        arapprover1_mgrid
        arapprover1_name
        arapprover1_status
        arapprover2_comments
        arapprover2_dateOfApproval
        arapprover2_dateOfEntry
        arapprover2_emailAddr
        arapprover2_id
        arapprover2_isdelegator
        arapprover2_lvl
        arapprover2_mgremailaddress
        arapprover2_mgrid
        arapprover2_name
        arapprover2_status
        arapprover3_comments
        arapprover3_dateOfApproval
        arapprover3_dateOfEntry
        arapprover3_emailAddr
        arapprover3_id
        arapprover3_isdelegator
        arapprover3_lvl
        arapprover3_mgremailaddress
        arapprover3_mgrid
        arapprover3_name
        arapprover3_status
        arapprover4_comments
        arapprover4_dateOfApproval
        arapprover4_dateOfEntry
        arapprover4_emailAddr
        arapprover4_id
        arapprover4_isdelegator
        arapprover4_lvl
        arapprover4_mgremailaddress
        arapprover4_mgrid
        arapprover4_name
        arapprover4_status
        arapprover5_comments
        arapprover5_dateOfApproval
        arapprover5_dateOfEntry
        arapprover5_emailAddr
        arapprover5_id
        arapprover5_isdelegator
        arapprover5_lvl
        arapprover5_mgremailaddress
        arapprover5_mgrid
        arapprover5_name
        arapprover5_status
        dType
        existARApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      arLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      arRequestSubmittedDate
      arRequestor
      arRequestorEmailAddress
      arRequestor_comments
      arSAPDocNo
      arSAPErrorMessage
      ar_doc_created_at
      ar_doc_created_by
      ar_doc_created_on
      ar_pay_term
      ar_pay_term_desc
      ar_posting_period
      ar_posting_year
      arresult
      attachments {
        createdAt
        createdBy
        fileName
        id
        index
        
      }
      commentHistoryItems {
        comment
        mailId
        time
        
      }
      currAPApproverLVL
      currARApproverLVL
      currency
      dig_sign
      exchangeRate
      executionID
      formStatus
      id
      invoiceDate
      invoiceNo
      isadminAction
      postingDate
      receivingCompanyCode
      recvBusinessPlace
      recvCountryCode
      recvCurrAmt
      requestCompletionDate
      requestDate
      requestDescription
      requestId
      requestType
      sapfailureMessage
      sendBusinessPlace
      sendCountryCode
      sendCurrAmt
      sendingCompanyCode
      stateName
      totalAPApproverLVL
      totalARApproverLVL
      totalAmt
      uniq_doc
      
    }
  }
`;
export const aractionperformerCrossChargeForm = /* GraphQL */ `
  mutation AractionperformerCrossChargeForm($input: CrossChargeFormInput!) {
    aractionperformerCrossChargeForm(input: $input) {
      actionAwaiting
      actionAwaitingEmailAddr
      actionType
      adminActionLvl_AP
      adminActionLvl_AR
      adminComments
      adminEmailAddress
      adminUserId
      adminsubmitedDate
      apApprovers {
        actualAPApprovers
        apRequestor
        apapprover1_comments
        apapprover1_dateOfApproval
        apapprover1_dateOfEntry
        apapprover1_emailAddr
        apapprover1_id
        apapprover1_isdelegator
        apapprover1_lvl
        apapprover1_mgremailaddress
        apapprover1_mgrid
        apapprover1_name
        apapprover1_status
        apapprover2_comments
        apapprover2_dateOfApproval
        apapprover2_dateOfEntry
        apapprover2_emailAddr
        apapprover2_id
        apapprover2_isdelegator
        apapprover2_lvl
        apapprover2_mgremailaddress
        apapprover2_mgrid
        apapprover2_name
        apapprover2_status
        apapprover3_comments
        apapprover3_dateOfApproval
        apapprover3_dateOfEntry
        apapprover3_emailAddr
        apapprover3_id
        apapprover3_isdelegator
        apapprover3_lvl
        apapprover3_mgremailaddress
        apapprover3_mgrid
        apapprover3_name
        apapprover3_status
        apapprover4_comments
        apapprover4_dateOfApproval
        apapprover4_dateOfEntry
        apapprover4_emailAddr
        apapprover4_id
        apapprover4_isdelegator
        apapprover4_lvl
        apapprover4_mgremailaddress
        apapprover4_mgrid
        apapprover4_name
        apapprover4_status
        apapprover5_comments
        apapprover5_dateOfApproval
        apapprover5_dateOfEntry
        apapprover5_emailAddr
        apapprover5_id
        apapprover5_isdelegator
        apapprover5_lvl
        apapprover5_mgremailaddress
        apapprover5_mgrid
        apapprover5_name
        apapprover5_status
        dType
        existAPApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      apLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      apRequestSubmittedDate
      apRequestor
      apRequestorEmailAddress
      apRequestor_comments
      apRequestor_dateOfEntry
      apRequestor_mgremailaddress
      apRequestor_mgrid
      apSAPDocNo
      ap_doc_created_at
      ap_doc_created_by
      ap_doc_created_on
      ap_pay_term
      ap_pay_term_desc
      ap_posting_period
      ap_posting_year
      apapSAPErrorMessage
      apresult
      arApprovers {
        actualARApprovers
        arRequestor
        arapprover1_comments
        arapprover1_dateOfApproval
        arapprover1_dateOfEntry
        arapprover1_emailAddr
        arapprover1_id
        arapprover1_isdelegator
        arapprover1_lvl
        arapprover1_mgremailaddress
        arapprover1_mgrid
        arapprover1_name
        arapprover1_status
        arapprover2_comments
        arapprover2_dateOfApproval
        arapprover2_dateOfEntry
        arapprover2_emailAddr
        arapprover2_id
        arapprover2_isdelegator
        arapprover2_lvl
        arapprover2_mgremailaddress
        arapprover2_mgrid
        arapprover2_name
        arapprover2_status
        arapprover3_comments
        arapprover3_dateOfApproval
        arapprover3_dateOfEntry
        arapprover3_emailAddr
        arapprover3_id
        arapprover3_isdelegator
        arapprover3_lvl
        arapprover3_mgremailaddress
        arapprover3_mgrid
        arapprover3_name
        arapprover3_status
        arapprover4_comments
        arapprover4_dateOfApproval
        arapprover4_dateOfEntry
        arapprover4_emailAddr
        arapprover4_id
        arapprover4_isdelegator
        arapprover4_lvl
        arapprover4_mgremailaddress
        arapprover4_mgrid
        arapprover4_name
        arapprover4_status
        arapprover5_comments
        arapprover5_dateOfApproval
        arapprover5_dateOfEntry
        arapprover5_emailAddr
        arapprover5_id
        arapprover5_isdelegator
        arapprover5_lvl
        arapprover5_mgremailaddress
        arapprover5_mgrid
        arapprover5_name
        arapprover5_status
        dType
        existARApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      arLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      arRequestSubmittedDate
      arRequestor
      arRequestorEmailAddress
      arRequestor_comments
      arSAPDocNo
      arSAPErrorMessage
      ar_doc_created_at
      ar_doc_created_by
      ar_doc_created_on
      ar_pay_term
      ar_pay_term_desc
      ar_posting_period
      ar_posting_year
      arresult
      attachments {
        createdAt
        createdBy
        fileName
        id
        index
        
      }
      commentHistoryItems {
        comment
        mailId
        time
        
      }
      currAPApproverLVL
      currARApproverLVL
      currency
      dig_sign
      exchangeRate
      executionID
      formStatus
      id
      invoiceDate
      invoiceNo
      isadminAction
      postingDate
      receivingCompanyCode
      recvBusinessPlace
      recvCountryCode
      recvCurrAmt
      requestCompletionDate
      requestDate
      requestDescription
      requestId
      requestType
      sapfailureMessage
      sendBusinessPlace
      sendCountryCode
      sendCurrAmt
      sendingCompanyCode
      stateName
      totalAPApproverLVL
      totalARApproverLVL
      totalAmt
      uniq_doc
      
    }
  }
`;
export const arretryCrossChargeForm = /* GraphQL */ `
  mutation ArretryCrossChargeForm($input: CrossChargeFormInput!) {
    arretryCrossChargeForm(input: $input) {
      actionAwaiting
      actionAwaitingEmailAddr
      actionType
      adminActionLvl_AP
      adminActionLvl_AR
      adminComments
      adminEmailAddress
      adminUserId
      adminsubmitedDate
      apApprovers {
        actualAPApprovers
        apRequestor
        apapprover1_comments
        apapprover1_dateOfApproval
        apapprover1_dateOfEntry
        apapprover1_emailAddr
        apapprover1_id
        apapprover1_isdelegator
        apapprover1_lvl
        apapprover1_mgremailaddress
        apapprover1_mgrid
        apapprover1_name
        apapprover1_status
        apapprover2_comments
        apapprover2_dateOfApproval
        apapprover2_dateOfEntry
        apapprover2_emailAddr
        apapprover2_id
        apapprover2_isdelegator
        apapprover2_lvl
        apapprover2_mgremailaddress
        apapprover2_mgrid
        apapprover2_name
        apapprover2_status
        apapprover3_comments
        apapprover3_dateOfApproval
        apapprover3_dateOfEntry
        apapprover3_emailAddr
        apapprover3_id
        apapprover3_isdelegator
        apapprover3_lvl
        apapprover3_mgremailaddress
        apapprover3_mgrid
        apapprover3_name
        apapprover3_status
        apapprover4_comments
        apapprover4_dateOfApproval
        apapprover4_dateOfEntry
        apapprover4_emailAddr
        apapprover4_id
        apapprover4_isdelegator
        apapprover4_lvl
        apapprover4_mgremailaddress
        apapprover4_mgrid
        apapprover4_name
        apapprover4_status
        apapprover5_comments
        apapprover5_dateOfApproval
        apapprover5_dateOfEntry
        apapprover5_emailAddr
        apapprover5_id
        apapprover5_isdelegator
        apapprover5_lvl
        apapprover5_mgremailaddress
        apapprover5_mgrid
        apapprover5_name
        apapprover5_status
        dType
        existAPApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      apLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      apRequestSubmittedDate
      apRequestor
      apRequestorEmailAddress
      apRequestor_comments
      apRequestor_dateOfEntry
      apRequestor_mgremailaddress
      apRequestor_mgrid
      apSAPDocNo
      ap_doc_created_at
      ap_doc_created_by
      ap_doc_created_on
      ap_pay_term
      ap_pay_term_desc
      ap_posting_period
      ap_posting_year
      apapSAPErrorMessage
      apresult
      arApprovers {
        actualARApprovers
        arRequestor
        arapprover1_comments
        arapprover1_dateOfApproval
        arapprover1_dateOfEntry
        arapprover1_emailAddr
        arapprover1_id
        arapprover1_isdelegator
        arapprover1_lvl
        arapprover1_mgremailaddress
        arapprover1_mgrid
        arapprover1_name
        arapprover1_status
        arapprover2_comments
        arapprover2_dateOfApproval
        arapprover2_dateOfEntry
        arapprover2_emailAddr
        arapprover2_id
        arapprover2_isdelegator
        arapprover2_lvl
        arapprover2_mgremailaddress
        arapprover2_mgrid
        arapprover2_name
        arapprover2_status
        arapprover3_comments
        arapprover3_dateOfApproval
        arapprover3_dateOfEntry
        arapprover3_emailAddr
        arapprover3_id
        arapprover3_isdelegator
        arapprover3_lvl
        arapprover3_mgremailaddress
        arapprover3_mgrid
        arapprover3_name
        arapprover3_status
        arapprover4_comments
        arapprover4_dateOfApproval
        arapprover4_dateOfEntry
        arapprover4_emailAddr
        arapprover4_id
        arapprover4_isdelegator
        arapprover4_lvl
        arapprover4_mgremailaddress
        arapprover4_mgrid
        arapprover4_name
        arapprover4_status
        arapprover5_comments
        arapprover5_dateOfApproval
        arapprover5_dateOfEntry
        arapprover5_emailAddr
        arapprover5_id
        arapprover5_isdelegator
        arapprover5_lvl
        arapprover5_mgremailaddress
        arapprover5_mgrid
        arapprover5_name
        arapprover5_status
        dType
        existARApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      arLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      arRequestSubmittedDate
      arRequestor
      arRequestorEmailAddress
      arRequestor_comments
      arSAPDocNo
      arSAPErrorMessage
      ar_doc_created_at
      ar_doc_created_by
      ar_doc_created_on
      ar_pay_term
      ar_pay_term_desc
      ar_posting_period
      ar_posting_year
      arresult
      attachments {
        createdAt
        createdBy
        fileName
        id
        index
        
      }
      commentHistoryItems {
        comment
        mailId
        time
        
      }
      currAPApproverLVL
      currARApproverLVL
      currency
      dig_sign
      exchangeRate
      executionID
      formStatus
      id
      invoiceDate
      invoiceNo
      isadminAction
      postingDate
      receivingCompanyCode
      recvBusinessPlace
      recvCountryCode
      recvCurrAmt
      requestCompletionDate
      requestDate
      requestDescription
      requestId
      requestType
      sapfailureMessage
      sendBusinessPlace
      sendCountryCode
      sendCurrAmt
      sendingCompanyCode
      stateName
      totalAPApproverLVL
      totalARApproverLVL
      totalAmt
      uniq_doc
      
    }
  }
`;
export const createCommDXchargeTwew = /* GraphQL */ `
  mutation CreateCommDXchargeTwew($input: CreateCommDXchargeTwewInput!) {
    createCommDXchargeTwew(input: $input) {
      dtype
      ewbez
      extwg
      id
      mandt
      
    }
  }
`;
export const createCommXchargeDAgrusers = /* GraphQL */ `
  mutation CreateCommXchargeDAgrusers(
    $input: CreateCommXchargeDAgrusersInput!
  ) {
    createCommXchargeDAgrusers(input: $input) {
      cclist
      change_dat
      change_tim
      dtype
      from_dat
      id
      mandt
      to_dat
      uname
      
    }
  }
`;
export const createCommXchargeDArApprovers = /* GraphQL */ `
  mutation CreateCommXchargeDArApprovers(
    $input: CreateCommXchargeDArApproversInput!
  ) {
    createCommXchargeDArApprovers(input: $input) {
      actualARApprovers
      arRequestor
      arapprover1_comments
      arapprover1_dateOfApproval
      arapprover1_dateOfEntry
      arapprover1_emailAddr
      arapprover1_id
      arapprover1_isdelegator
      arapprover1_lvl
      arapprover1_mgremailaddress
      arapprover1_mgrid
      arapprover1_name
      arapprover1_status
      arapprover2_comments
      arapprover2_dateOfApproval
      arapprover2_dateOfEntry
      arapprover2_emailAddr
      arapprover2_id
      arapprover2_isdelegator
      arapprover2_lvl
      arapprover2_mgremailaddress
      arapprover2_mgrid
      arapprover2_name
      arapprover2_status
      arapprover3_comments
      arapprover3_dateOfApproval
      arapprover3_dateOfEntry
      arapprover3_emailAddr
      arapprover3_id
      arapprover3_isdelegator
      arapprover3_lvl
      arapprover3_mgremailaddress
      arapprover3_mgrid
      arapprover3_name
      arapprover3_status
      arapprover4_comments
      arapprover4_dateOfApproval
      arapprover4_dateOfEntry
      arapprover4_emailAddr
      arapprover4_id
      arapprover4_isdelegator
      arapprover4_lvl
      arapprover4_mgremailaddress
      arapprover4_mgrid
      arapprover4_name
      arapprover4_status
      arapprover5_comments
      arapprover5_dateOfApproval
      arapprover5_dateOfEntry
      arapprover5_emailAddr
      arapprover5_id
      arapprover5_isdelegator
      arapprover5_lvl
      arapprover5_mgremailaddress
      arapprover5_mgrid
      arapprover5_name
      arapprover5_status
      dType
      existARApprovers
      formStatus
      id
      requestDate
      requestId
      
    }
  }
`;
export const createCommXchargeDCepc = /* GraphQL */ `
  mutation CreateCommXchargeDCepc($input: CreateCommXchargeDCepcInput!) {
    createCommXchargeDCepc(input: $input) {
      bukrs
      dataab
      databi
      dtype
      ersda
      id
      kokrs
      ltext
      mandt
      prctr
      
    }
  }
`;
export const createCommXchargeDCoas = /* GraphQL */ `
  mutation CreateCommXchargeDCoas($input: CreateCommXchargeDCoasInput!) {
    createCommXchargeDCoas(input: $input) {
      aufnr
      bukrs
      dtype
      id
      ktext
      mandt
      
    }
  }
`;
export const createCommXchargeDCsks = /* GraphQL */ `
  mutation CreateCommXchargeDCsks($input: CreateCommXchargeDCsksInput!) {
    createCommXchargeDCsks(input: $input) {
      bukrs
      dataab
      databi
      dtype
      ersda
      id
      kokrs
      kostl
      ltext
      mandt
      prctr
      txjcd
      
    }
  }
`;
export const createCommXchargeDHrusd2 = /* GraphQL */ `
  mutation CreateCommXchargeDHrusd2($input: CreateCommXchargeDHrusd2Input!) {
    createCommXchargeDHrusd2(input: $input) {
      active
      begda
      dtype
      endda
      id
      mandt
      rep_name
      us_name
      
    }
  }
`;
export const createCommXchargeDJ1bbranch = /* GraphQL */ `
  mutation CreateCommXchargeDJ1bbranch(
    $input: CreateCommXchargeDJ1bbranchInput!
  ) {
    createCommXchargeDJ1bbranch(input: $input) {
      branch
      bukrs
      cgc_branch
      dtype
      id
      mandt
      name
      
    }
  }
`;
export const createCommXchargeDKNA1 = /* GraphQL */ `
  mutation CreateCommXchargeDKNA1($input: CreateCommXchargeDKNA1Input!) {
    createCommXchargeDKNA1(input: $input) {
      dtype
      id
      kunnr
      mandt
      vbund
      
    }
  }
`;
export const createCommXchargeDKnb1 = /* GraphQL */ `
  mutation CreateCommXchargeDKnb1($input: CreateCommXchargeDKnb1Input!) {
    createCommXchargeDKnb1(input: $input) {
      bukrs
      dtype
      id
      kunnr
      mandt
      name1
      
    }
  }
`;
export const createCommXchargeDLFA1 = /* GraphQL */ `
  mutation CreateCommXchargeDLFA1($input: CreateCommXchargeDLFA1Input!) {
    createCommXchargeDLFA1(input: $input) {
      dtype
      erdat
      id
      lifnr
      loevm
      mandt
      vbund
      
    }
  }
`;
export const createCommXchargeDLFB1 = /* GraphQL */ `
  mutation CreateCommXchargeDLFB1($input: CreateCommXchargeDLFB1Input!) {
    createCommXchargeDLFB1(input: $input) {
      bukrs
      dtype
      id
      lifnr
      mandt
      name1
      
    }
  }
`;
export const createCommXchargeDMara = /* GraphQL */ `
  mutation CreateCommXchargeDMara($input: CreateCommXchargeDMaraInput!) {
    createCommXchargeDMara(input: $input) {
      bismt
      dtype
      ersda
      extwg
      id
      laeda
      maktx
      mandt
      matkl
      matnr
      mtart
      
    }
  }
`;
export const createCommXchargeDReportdashboard = /* GraphQL */ `
  mutation CreateCommXchargeDReportdashboard(
    $input: CreateCommXchargeDReportdashboardInput!
  ) {
    createCommXchargeDReportdashboard(input: $input) {
      actionAwaiting
      actionAwaitingEmailAddr
      actionType
      apRequestSubmittedDate
      apRequestorEmailId
      apRequestorId
      apRequestor_dateOfEntry
      apRequestor_mgremailaddress
      apRequestor_mgrid
      apSAPDocNo
      apSAPErrorMessage
      ap_doc_created_at
      ap_doc_created_by
      ap_doc_created_on
      ap_pay_term
      ap_pay_term_desc
      ap_posting_period
      ap_posting_year
      apapprover1_comments
      apapprover1_dateOfApproval
      apapprover1_dateOfEntry
      apapprover1_emailAddr
      apapprover1_id
      apapprover1_isdelegator
      apapprover1_lvl
      apapprover1_mgremailaddress
      apapprover1_mgrid
      apapprover1_name
      apapprover1_status
      apapprover2_comments
      apapprover2_dateOfApproval
      apapprover2_dateOfEntry
      apapprover2_emailAddr
      apapprover2_id
      apapprover2_isdelegator
      apapprover2_lvl
      apapprover2_mgremailaddress
      apapprover2_mgrid
      apapprover2_name
      apapprover2_status
      apapprover3_comments
      apapprover3_dateOfApproval
      apapprover3_dateOfEntry
      apapprover3_emailAddr
      apapprover3_id
      apapprover3_isdelegator
      apapprover3_lvl
      apapprover3_mgremailaddress
      apapprover3_mgrid
      apapprover3_name
      apapprover3_status
      apapprover4_comments
      apapprover4_dateOfApproval
      apapprover4_dateOfEntry
      apapprover4_emailAddr
      apapprover4_id
      apapprover4_isdelegator
      apapprover4_lvl
      apapprover4_mgremailaddress
      apapprover4_mgrid
      apapprover4_name
      apapprover4_status
      apapprover5_comments
      apapprover5_dateOfApproval
      apapprover5_dateOfEntry
      apapprover5_emailAddr
      apapprover5_id
      apapprover5_isdelegator
      apapprover5_lvl
      apapprover5_mgremailaddress
      apapprover5_mgrid
      apapprover5_name
      apapprover5_status
      apresult
      arRequestSubmittedDate
      arRequestorEmailId
      arRequestorId
      arSAPDocNo
      arSAPErrorMessage
      ar_doc_created_at
      ar_doc_created_by
      ar_doc_created_on
      ar_pay_term
      ar_pay_term_desc
      ar_posting_period
      ar_posting_year
      arapprover1_comments
      arapprover1_dateOfApproval
      arapprover1_dateOfEntry
      arapprover1_emailAddr
      arapprover1_id
      arapprover1_isdelegator
      arapprover1_lvl
      arapprover1_mgremailaddress
      arapprover1_mgrid
      arapprover1_name
      arapprover1_status
      arapprover2_comments
      arapprover2_dateOfApproval
      arapprover2_dateOfEntry
      arapprover2_emailAddr
      arapprover2_id
      arapprover2_isdelegator
      arapprover2_lvl
      arapprover2_mgremailaddress
      arapprover2_mgrid
      arapprover2_name
      arapprover2_status
      arapprover3_comments
      arapprover3_dateOfApproval
      arapprover3_dateOfEntry
      arapprover3_emailAddr
      arapprover3_id
      arapprover3_isdelegator
      arapprover3_lvl
      arapprover3_mgremailaddress
      arapprover3_mgrid
      arapprover3_name
      arapprover3_status
      arapprover4_comments
      arapprover4_dateOfApproval
      arapprover4_dateOfEntry
      arapprover4_emailAddr
      arapprover4_id
      arapprover4_isdelegator
      arapprover4_lvl
      arapprover4_mgremailaddress
      arapprover4_mgrid
      arapprover4_name
      arapprover4_status
      arapprover5_comments
      arapprover5_dateOfApproval
      arapprover5_dateOfEntry
      arapprover5_emailAddr
      arapprover5_id
      arapprover5_isdelegator
      arapprover5_lvl
      arapprover5_mgremailaddress
      arapprover5_mgrid
      arapprover5_name
      arapprover5_status
      arresult
      currAPApproverLVL
      currARApproverLVL
      currencyType
      dig_sign
      exchangeRate
      id
      invoiceDate
      invoiceNo
      postingDate
      receivingCompanyCode
      recvBusinessPlace
      recvCountryCode
      recvCurrAmt
      requestCompletionDate
      requestDate
      requestDescription
      requestId
      requestType
      sAPFailureMessage
      sendBusinessPlace
      sendCountryCode
      sendCurrAmt
      sendingCompanyCode
      stateName
      status
      taskToken
      totalAPApproverLVL
      totalARApproverLVL
      totalAmt
      uniq_doc
      
    }
  }
`;
export const createCommXchargeDSkb1 = /* GraphQL */ `
  mutation CreateCommXchargeDSkb1($input: CreateCommXchargeDSkb1Input!) {
    createCommXchargeDSkb1(input: $input) {
      bukrs
      dtype
      id
      mandt
      saknr
      txt20
      
    }
  }
`;
export const createCommXchargeDT001 = /* GraphQL */ `
  mutation CreateCommXchargeDT001($input: CreateCommXchargeDT001Input!) {
    createCommXchargeDT001(input: $input) {
      bukrs
      butxt
      city1
      country
      dtype
      house_num1
      id
      land1
      mandt
      region
      street
      vbList
      waers
      wfvar
      
    }
  }
`;
export const createCommXchargeDT006A = /* GraphQL */ `
  mutation CreateCommXchargeDT006A($input: CreateCommXchargeDT006AInput!) {
    createCommXchargeDT006A(input: $input) {
      dtype
      id
      mandt
      msehi
      msehl
      mseht
      spras
      
    }
  }
`;
export const createCommXchargeDT007A = /* GraphQL */ `
  mutation CreateCommXchargeDT007A($input: CreateCommXchargeDT007AInput!) {
    createCommXchargeDT007A(input: $input) {
      dtype
      id
      kalsm
      land1
      mandt
      mwart
      mwskz
      text1
      xinact
      
    }
  }
`;
export const createCommXchargeDTcurc = /* GraphQL */ `
  mutation CreateCommXchargeDTcurc($input: CreateCommXchargeDTcurcInput!) {
    createCommXchargeDTcurc(input: $input) {
      currdec
      id
      mandt
      waers
      
    }
  }
`;
export const createCommXchargeDTradingpartner = /* GraphQL */ `
  mutation CreateCommXchargeDTradingpartner(
    $input: CreateCommXchargeDTradingpartnerInput!
  ) {
    createCommXchargeDTradingpartner(input: $input) {
      companydesc
      fitcode
      id
      marscode
      tradingpartner
      
    }
  }
`;
export const createCommXchargeDUsr01 = /* GraphQL */ `
  mutation CreateCommXchargeDUsr01($input: CreateCommXchargeDUsr01Input!) {
    createCommXchargeDUsr01(input: $input) {
      bname
      dcpfm
      decDesc
      dtype
      id
      mandt
      
    }
  }
`;
export const createCommXchargeDZfinApproval = /* GraphQL */ `
  mutation CreateCommXchargeDZfinApproval(
    $input: CreateCommXchargeDZfinApprovalInput!
  ) {
    createCommXchargeDZfinApproval(input: $input) {
      butxt
      company_code
      dtype
      emp_id
      emp_name
      id
      land1
      level1_id
      level1_name
      level2_id
      level2_name
      level3_id
      level3_name
      level4_id
      level4_name
      level5_id
      level5_name
      mandt
      rel_grp
      
    }
  }
`;
export const createCrossChargerequest = /* GraphQL */ `
  mutation CreateCrossChargerequest($input: CrossChargeFormInput!) {
    createCrossChargerequest(input: $input) {
      id
      
    }
  }
`;
export const deleteCommDXchargeTwew = /* GraphQL */ `
  mutation DeleteCommDXchargeTwew($input: DeleteCommDXchargeTwewInput!) {
    deleteCommDXchargeTwew(input: $input) {
      dtype
      ewbez
      extwg
      id
      mandt
      
    }
  }
`;
export const deleteCommXchargeDAgrusers = /* GraphQL */ `
  mutation DeleteCommXchargeDAgrusers(
    $input: DeleteCommXchargeDAgrusersInput!
  ) {
    deleteCommXchargeDAgrusers(input: $input) {
      cclist
      change_dat
      change_tim
      dtype
      from_dat
      id
      mandt
      to_dat
      uname
      
    }
  }
`;
export const deleteCommXchargeDArApprovers = /* GraphQL */ `
  mutation DeleteCommXchargeDArApprovers(
    $input: DeleteCommXchargeDArApproversInput!
  ) {
    deleteCommXchargeDArApprovers(input: $input) {
      actualARApprovers
      arRequestor
      arapprover1_comments
      arapprover1_dateOfApproval
      arapprover1_dateOfEntry
      arapprover1_emailAddr
      arapprover1_id
      arapprover1_isdelegator
      arapprover1_lvl
      arapprover1_mgremailaddress
      arapprover1_mgrid
      arapprover1_name
      arapprover1_status
      arapprover2_comments
      arapprover2_dateOfApproval
      arapprover2_dateOfEntry
      arapprover2_emailAddr
      arapprover2_id
      arapprover2_isdelegator
      arapprover2_lvl
      arapprover2_mgremailaddress
      arapprover2_mgrid
      arapprover2_name
      arapprover2_status
      arapprover3_comments
      arapprover3_dateOfApproval
      arapprover3_dateOfEntry
      arapprover3_emailAddr
      arapprover3_id
      arapprover3_isdelegator
      arapprover3_lvl
      arapprover3_mgremailaddress
      arapprover3_mgrid
      arapprover3_name
      arapprover3_status
      arapprover4_comments
      arapprover4_dateOfApproval
      arapprover4_dateOfEntry
      arapprover4_emailAddr
      arapprover4_id
      arapprover4_isdelegator
      arapprover4_lvl
      arapprover4_mgremailaddress
      arapprover4_mgrid
      arapprover4_name
      arapprover4_status
      arapprover5_comments
      arapprover5_dateOfApproval
      arapprover5_dateOfEntry
      arapprover5_emailAddr
      arapprover5_id
      arapprover5_isdelegator
      arapprover5_lvl
      arapprover5_mgremailaddress
      arapprover5_mgrid
      arapprover5_name
      arapprover5_status
      dType
      existARApprovers
      formStatus
      id
      requestDate
      requestId
      
    }
  }
`;
export const deleteCommXchargeDCepc = /* GraphQL */ `
  mutation DeleteCommXchargeDCepc($input: DeleteCommXchargeDCepcInput!) {
    deleteCommXchargeDCepc(input: $input) {
      bukrs
      dataab
      databi
      dtype
      ersda
      id
      kokrs
      ltext
      mandt
      prctr
      
    }
  }
`;
export const deleteCommXchargeDCoas = /* GraphQL */ `
  mutation DeleteCommXchargeDCoas($input: DeleteCommXchargeDCoasInput!) {
    deleteCommXchargeDCoas(input: $input) {
      aufnr
      bukrs
      dtype
      id
      ktext
      mandt
      
    }
  }
`;
export const deleteCommXchargeDCsks = /* GraphQL */ `
  mutation DeleteCommXchargeDCsks($input: DeleteCommXchargeDCsksInput!) {
    deleteCommXchargeDCsks(input: $input) {
      bukrs
      dataab
      databi
      dtype
      ersda
      id
      kokrs
      kostl
      ltext
      mandt
      prctr
      txjcd
      
    }
  }
`;
export const deleteCommXchargeDHrusd2 = /* GraphQL */ `
  mutation DeleteCommXchargeDHrusd2($input: DeleteCommXchargeDHrusd2Input!) {
    deleteCommXchargeDHrusd2(input: $input) {
      active
      begda
      dtype
      endda
      id
      mandt
      rep_name
      us_name
      
    }
  }
`;
export const deleteCommXchargeDJ1bbranch = /* GraphQL */ `
  mutation DeleteCommXchargeDJ1bbranch(
    $input: DeleteCommXchargeDJ1bbranchInput!
  ) {
    deleteCommXchargeDJ1bbranch(input: $input) {
      branch
      bukrs
      cgc_branch
      dtype
      id
      mandt
      name
      
    }
  }
`;
export const deleteCommXchargeDKNA1 = /* GraphQL */ `
  mutation DeleteCommXchargeDKNA1($input: DeleteCommXchargeDKNA1Input!) {
    deleteCommXchargeDKNA1(input: $input) {
      dtype
      id
      kunnr
      mandt
      vbund
      
    }
  }
`;
export const deleteCommXchargeDKnb1 = /* GraphQL */ `
  mutation DeleteCommXchargeDKnb1($input: DeleteCommXchargeDKnb1Input!) {
    deleteCommXchargeDKnb1(input: $input) {
      bukrs
      dtype
      id
      kunnr
      mandt
      name1
      
    }
  }
`;
export const deleteCommXchargeDLFA1 = /* GraphQL */ `
  mutation DeleteCommXchargeDLFA1($input: DeleteCommXchargeDLFA1Input!) {
    deleteCommXchargeDLFA1(input: $input) {
      dtype
      erdat
      id
      lifnr
      loevm
      mandt
      vbund
      
    }
  }
`;
export const deleteCommXchargeDLFB1 = /* GraphQL */ `
  mutation DeleteCommXchargeDLFB1($input: DeleteCommXchargeDLFB1Input!) {
    deleteCommXchargeDLFB1(input: $input) {
      bukrs
      dtype
      id
      lifnr
      mandt
      name1
      
    }
  }
`;
export const deleteCommXchargeDMara = /* GraphQL */ `
  mutation DeleteCommXchargeDMara($input: DeleteCommXchargeDMaraInput!) {
    deleteCommXchargeDMara(input: $input) {
      bismt
      dtype
      ersda
      extwg
      id
      laeda
      maktx
      mandt
      matkl
      matnr
      mtart
      
    }
  }
`;
export const deleteCommXchargeDReportdashboard = /* GraphQL */ `
  mutation DeleteCommXchargeDReportdashboard(
    $input: DeleteCommXchargeDReportdashboardInput!
  ) {
    deleteCommXchargeDReportdashboard(input: $input) {
      actionAwaiting
      actionAwaitingEmailAddr
      actionType
      apRequestSubmittedDate
      apRequestorEmailId
      apRequestorId
      apRequestor_dateOfEntry
      apRequestor_mgremailaddress
      apRequestor_mgrid
      apSAPDocNo
      apSAPErrorMessage
      ap_doc_created_at
      ap_doc_created_by
      ap_doc_created_on
      ap_pay_term
      ap_pay_term_desc
      ap_posting_period
      ap_posting_year
      apapprover1_comments
      apapprover1_dateOfApproval
      apapprover1_dateOfEntry
      apapprover1_emailAddr
      apapprover1_id
      apapprover1_isdelegator
      apapprover1_lvl
      apapprover1_mgremailaddress
      apapprover1_mgrid
      apapprover1_name
      apapprover1_status
      apapprover2_comments
      apapprover2_dateOfApproval
      apapprover2_dateOfEntry
      apapprover2_emailAddr
      apapprover2_id
      apapprover2_isdelegator
      apapprover2_lvl
      apapprover2_mgremailaddress
      apapprover2_mgrid
      apapprover2_name
      apapprover2_status
      apapprover3_comments
      apapprover3_dateOfApproval
      apapprover3_dateOfEntry
      apapprover3_emailAddr
      apapprover3_id
      apapprover3_isdelegator
      apapprover3_lvl
      apapprover3_mgremailaddress
      apapprover3_mgrid
      apapprover3_name
      apapprover3_status
      apapprover4_comments
      apapprover4_dateOfApproval
      apapprover4_dateOfEntry
      apapprover4_emailAddr
      apapprover4_id
      apapprover4_isdelegator
      apapprover4_lvl
      apapprover4_mgremailaddress
      apapprover4_mgrid
      apapprover4_name
      apapprover4_status
      apapprover5_comments
      apapprover5_dateOfApproval
      apapprover5_dateOfEntry
      apapprover5_emailAddr
      apapprover5_id
      apapprover5_isdelegator
      apapprover5_lvl
      apapprover5_mgremailaddress
      apapprover5_mgrid
      apapprover5_name
      apapprover5_status
      apresult
      arRequestSubmittedDate
      arRequestorEmailId
      arRequestorId
      arSAPDocNo
      arSAPErrorMessage
      ar_doc_created_at
      ar_doc_created_by
      ar_doc_created_on
      ar_pay_term
      ar_pay_term_desc
      ar_posting_period
      ar_posting_year
      arapprover1_comments
      arapprover1_dateOfApproval
      arapprover1_dateOfEntry
      arapprover1_emailAddr
      arapprover1_id
      arapprover1_isdelegator
      arapprover1_lvl
      arapprover1_mgremailaddress
      arapprover1_mgrid
      arapprover1_name
      arapprover1_status
      arapprover2_comments
      arapprover2_dateOfApproval
      arapprover2_dateOfEntry
      arapprover2_emailAddr
      arapprover2_id
      arapprover2_isdelegator
      arapprover2_lvl
      arapprover2_mgremailaddress
      arapprover2_mgrid
      arapprover2_name
      arapprover2_status
      arapprover3_comments
      arapprover3_dateOfApproval
      arapprover3_dateOfEntry
      arapprover3_emailAddr
      arapprover3_id
      arapprover3_isdelegator
      arapprover3_lvl
      arapprover3_mgremailaddress
      arapprover3_mgrid
      arapprover3_name
      arapprover3_status
      arapprover4_comments
      arapprover4_dateOfApproval
      arapprover4_dateOfEntry
      arapprover4_emailAddr
      arapprover4_id
      arapprover4_isdelegator
      arapprover4_lvl
      arapprover4_mgremailaddress
      arapprover4_mgrid
      arapprover4_name
      arapprover4_status
      arapprover5_comments
      arapprover5_dateOfApproval
      arapprover5_dateOfEntry
      arapprover5_emailAddr
      arapprover5_id
      arapprover5_isdelegator
      arapprover5_lvl
      arapprover5_mgremailaddress
      arapprover5_mgrid
      arapprover5_name
      arapprover5_status
      arresult
      currAPApproverLVL
      currARApproverLVL
      currencyType
      dig_sign
      exchangeRate
      id
      invoiceDate
      invoiceNo
      postingDate
      receivingCompanyCode
      recvBusinessPlace
      recvCountryCode
      recvCurrAmt
      requestCompletionDate
      requestDate
      requestDescription
      requestId
      requestType
      sAPFailureMessage
      sendBusinessPlace
      sendCountryCode
      sendCurrAmt
      sendingCompanyCode
      stateName
      status
      taskToken
      totalAPApproverLVL
      totalARApproverLVL
      totalAmt
      uniq_doc
      
    }
  }
`;
export const deleteCommXchargeDSkb1 = /* GraphQL */ `
  mutation DeleteCommXchargeDSkb1($input: DeleteCommXchargeDSkb1Input!) {
    deleteCommXchargeDSkb1(input: $input) {
      bukrs
      dtype
      id
      mandt
      saknr
      txt20
      
    }
  }
`;
export const deleteCommXchargeDT001 = /* GraphQL */ `
  mutation DeleteCommXchargeDT001($input: DeleteCommXchargeDT001Input!) {
    deleteCommXchargeDT001(input: $input) {
      bukrs
      butxt
      city1
      country
      dtype
      house_num1
      id
      land1
      mandt
      region
      street
      vbList
      waers
      wfvar
      
    }
  }
`;
export const deleteCommXchargeDT006A = /* GraphQL */ `
  mutation DeleteCommXchargeDT006A($input: DeleteCommXchargeDT006AInput!) {
    deleteCommXchargeDT006A(input: $input) {
      dtype
      id
      mandt
      msehi
      msehl
      mseht
      spras
      
    }
  }
`;
export const deleteCommXchargeDT007A = /* GraphQL */ `
  mutation DeleteCommXchargeDT007A($input: DeleteCommXchargeDT007AInput!) {
    deleteCommXchargeDT007A(input: $input) {
      dtype
      id
      kalsm
      land1
      mandt
      mwart
      mwskz
      text1
      xinact
      
    }
  }
`;
export const deleteCommXchargeDTcurc = /* GraphQL */ `
  mutation DeleteCommXchargeDTcurc($input: DeleteCommXchargeDTcurcInput!) {
    deleteCommXchargeDTcurc(input: $input) {
      currdec
      id
      mandt
      waers
      
    }
  }
`;
export const deleteCommXchargeDTradingpartner = /* GraphQL */ `
  mutation DeleteCommXchargeDTradingpartner(
    $input: DeleteCommXchargeDTradingpartnerInput!
  ) {
    deleteCommXchargeDTradingpartner(input: $input) {
      companydesc
      fitcode
      id
      marscode
      tradingpartner
      
    }
  }
`;
export const deleteCommXchargeDUsr01 = /* GraphQL */ `
  mutation DeleteCommXchargeDUsr01($input: DeleteCommXchargeDUsr01Input!) {
    deleteCommXchargeDUsr01(input: $input) {
      bname
      dcpfm
      decDesc
      dtype
      id
      mandt
      
    }
  }
`;
export const deleteCommXchargeDZfinApproval = /* GraphQL */ `
  mutation DeleteCommXchargeDZfinApproval(
    $input: DeleteCommXchargeDZfinApprovalInput!
  ) {
    deleteCommXchargeDZfinApproval(input: $input) {
      butxt
      company_code
      dtype
      emp_id
      emp_name
      id
      land1
      level1_id
      level1_name
      level2_id
      level2_name
      level3_id
      level3_name
      level4_id
      level4_name
      level5_id
      level5_name
      mandt
      rel_grp
      
    }
  }
`;
export const submitCrossChargeForm = /* GraphQL */ `
  mutation SubmitCrossChargeForm($input: CrossChargeFormInput!) {
    submitCrossChargeForm(input: $input) {
      actionAwaiting
      actionAwaitingEmailAddr
      actionType
      adminActionLvl_AP
      adminActionLvl_AR
      adminComments
      adminEmailAddress
      adminUserId
      adminsubmitedDate
      apApprovers {
        actualAPApprovers
        apRequestor
        apapprover1_comments
        apapprover1_dateOfApproval
        apapprover1_dateOfEntry
        apapprover1_emailAddr
        apapprover1_id
        apapprover1_isdelegator
        apapprover1_lvl
        apapprover1_mgremailaddress
        apapprover1_mgrid
        apapprover1_name
        apapprover1_status
        apapprover2_comments
        apapprover2_dateOfApproval
        apapprover2_dateOfEntry
        apapprover2_emailAddr
        apapprover2_id
        apapprover2_isdelegator
        apapprover2_lvl
        apapprover2_mgremailaddress
        apapprover2_mgrid
        apapprover2_name
        apapprover2_status
        apapprover3_comments
        apapprover3_dateOfApproval
        apapprover3_dateOfEntry
        apapprover3_emailAddr
        apapprover3_id
        apapprover3_isdelegator
        apapprover3_lvl
        apapprover3_mgremailaddress
        apapprover3_mgrid
        apapprover3_name
        apapprover3_status
        apapprover4_comments
        apapprover4_dateOfApproval
        apapprover4_dateOfEntry
        apapprover4_emailAddr
        apapprover4_id
        apapprover4_isdelegator
        apapprover4_lvl
        apapprover4_mgremailaddress
        apapprover4_mgrid
        apapprover4_name
        apapprover4_status
        apapprover5_comments
        apapprover5_dateOfApproval
        apapprover5_dateOfEntry
        apapprover5_emailAddr
        apapprover5_id
        apapprover5_isdelegator
        apapprover5_lvl
        apapprover5_mgremailaddress
        apapprover5_mgrid
        apapprover5_name
        apapprover5_status
        dType
        existAPApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      apLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      apRequestSubmittedDate
      apRequestor
      apRequestorEmailAddress
      apRequestor_comments
      apRequestor_dateOfEntry
      apRequestor_mgremailaddress
      apRequestor_mgrid
      apSAPDocNo
      ap_doc_created_at
      ap_doc_created_by
      ap_doc_created_on
      ap_pay_term
      ap_pay_term_desc
      ap_posting_period
      ap_posting_year
      apapSAPErrorMessage
      apresult
      arApprovers {
        actualARApprovers
        arRequestor
        arapprover1_comments
        arapprover1_dateOfApproval
        arapprover1_dateOfEntry
        arapprover1_emailAddr
        arapprover1_id
        arapprover1_isdelegator
        arapprover1_lvl
        arapprover1_mgremailaddress
        arapprover1_mgrid
        arapprover1_name
        arapprover1_status
        arapprover2_comments
        arapprover2_dateOfApproval
        arapprover2_dateOfEntry
        arapprover2_emailAddr
        arapprover2_id
        arapprover2_isdelegator
        arapprover2_lvl
        arapprover2_mgremailaddress
        arapprover2_mgrid
        arapprover2_name
        arapprover2_status
        arapprover3_comments
        arapprover3_dateOfApproval
        arapprover3_dateOfEntry
        arapprover3_emailAddr
        arapprover3_id
        arapprover3_isdelegator
        arapprover3_lvl
        arapprover3_mgremailaddress
        arapprover3_mgrid
        arapprover3_name
        arapprover3_status
        arapprover4_comments
        arapprover4_dateOfApproval
        arapprover4_dateOfEntry
        arapprover4_emailAddr
        arapprover4_id
        arapprover4_isdelegator
        arapprover4_lvl
        arapprover4_mgremailaddress
        arapprover4_mgrid
        arapprover4_name
        arapprover4_status
        arapprover5_comments
        arapprover5_dateOfApproval
        arapprover5_dateOfEntry
        arapprover5_emailAddr
        arapprover5_id
        arapprover5_isdelegator
        arapprover5_lvl
        arapprover5_mgremailaddress
        arapprover5_mgrid
        arapprover5_name
        arapprover5_status
        dType
        existARApprovers
        formStatus
        id
        requestDate
        requestId
        
      }
      arLineItems {
        account
        acct_descr
        amount
        cctr
        cur_key
        emg
        id
        item
        lineItemAssignment
        lineItemText
        orderNumber
        postingKey
        prctr
        product
        qty
        tax
        taxCode
        taxJurisdiction
        tax_base_amt
        tax_code_desc
        tax_cur_key
        tax_percent
        tradingPartner
        uom
        wbselement
        
      }
      arRequestSubmittedDate
      arRequestor
      arRequestorEmailAddress
      arRequestor_comments
      arSAPDocNo
      arSAPErrorMessage
      ar_doc_created_at
      ar_doc_created_by
      ar_doc_created_on
      ar_pay_term
      ar_pay_term_desc
      ar_posting_period
      ar_posting_year
      arresult
      attachments {
        createdAt
        createdBy
        fileName
        id
        index
        
      }
      commentHistoryItems {
        comment
        mailId
        time
        
      }
      currAPApproverLVL
      currARApproverLVL
      currency
      dig_sign
      exchangeRate
      executionID
      formStatus
      id
      invoiceDate
      invoiceNo
      isadminAction
      postingDate
      receivingCompanyCode
      recvBusinessPlace
      recvCountryCode
      recvCurrAmt
      requestCompletionDate
      requestDate
      requestDescription
      requestId
      requestType
      sapfailureMessage
      sendBusinessPlace
      sendCountryCode
      sendCurrAmt
      sendingCompanyCode
      stateName
      totalAPApproverLVL
      totalARApproverLVL
      totalAmt
      uniq_doc
      
    }
  }
`;
export const updateCommDXchargeTwew = /* GraphQL */ `
  mutation UpdateCommDXchargeTwew($input: UpdateCommDXchargeTwewInput!) {
    updateCommDXchargeTwew(input: $input) {
      dtype
      ewbez
      extwg
      id
      mandt
      
    }
  }
`;
export const updateCommXchargeDAgrusers = /* GraphQL */ `
  mutation UpdateCommXchargeDAgrusers(
    $input: UpdateCommXchargeDAgrusersInput!
  ) {
    updateCommXchargeDAgrusers(input: $input) {
      cclist
      change_dat
      change_tim
      dtype
      from_dat
      id
      mandt
      to_dat
      uname
      
    }
  }
`;
export const updateCommXchargeDArApprovers = /* GraphQL */ `
  mutation UpdateCommXchargeDArApprovers(
    $input: UpdateCommXchargeDArApproversInput!
  ) {
    updateCommXchargeDArApprovers(input: $input) {
      actualARApprovers
      arRequestor
      arapprover1_comments
      arapprover1_dateOfApproval
      arapprover1_dateOfEntry
      arapprover1_emailAddr
      arapprover1_id
      arapprover1_isdelegator
      arapprover1_lvl
      arapprover1_mgremailaddress
      arapprover1_mgrid
      arapprover1_name
      arapprover1_status
      arapprover2_comments
      arapprover2_dateOfApproval
      arapprover2_dateOfEntry
      arapprover2_emailAddr
      arapprover2_id
      arapprover2_isdelegator
      arapprover2_lvl
      arapprover2_mgremailaddress
      arapprover2_mgrid
      arapprover2_name
      arapprover2_status
      arapprover3_comments
      arapprover3_dateOfApproval
      arapprover3_dateOfEntry
      arapprover3_emailAddr
      arapprover3_id
      arapprover3_isdelegator
      arapprover3_lvl
      arapprover3_mgremailaddress
      arapprover3_mgrid
      arapprover3_name
      arapprover3_status
      arapprover4_comments
      arapprover4_dateOfApproval
      arapprover4_dateOfEntry
      arapprover4_emailAddr
      arapprover4_id
      arapprover4_isdelegator
      arapprover4_lvl
      arapprover4_mgremailaddress
      arapprover4_mgrid
      arapprover4_name
      arapprover4_status
      arapprover5_comments
      arapprover5_dateOfApproval
      arapprover5_dateOfEntry
      arapprover5_emailAddr
      arapprover5_id
      arapprover5_isdelegator
      arapprover5_lvl
      arapprover5_mgremailaddress
      arapprover5_mgrid
      arapprover5_name
      arapprover5_status
      dType
      existARApprovers
      formStatus
      id
      requestDate
      requestId
      
    }
  }
`;
export const updateCommXchargeDCepc = /* GraphQL */ `
  mutation UpdateCommXchargeDCepc($input: UpdateCommXchargeDCepcInput!) {
    updateCommXchargeDCepc(input: $input) {
      bukrs
      dataab
      databi
      dtype
      ersda
      id
      kokrs
      ltext
      mandt
      prctr
      
    }
  }
`;
export const updateCommXchargeDCoas = /* GraphQL */ `
  mutation UpdateCommXchargeDCoas($input: UpdateCommXchargeDCoasInput!) {
    updateCommXchargeDCoas(input: $input) {
      aufnr
      bukrs
      dtype
      id
      ktext
      mandt
      
    }
  }
`;
export const updateCommXchargeDCsks = /* GraphQL */ `
  mutation UpdateCommXchargeDCsks($input: UpdateCommXchargeDCsksInput!) {
    updateCommXchargeDCsks(input: $input) {
      bukrs
      dataab
      databi
      dtype
      ersda
      id
      kokrs
      kostl
      ltext
      mandt
      prctr
      txjcd
      
    }
  }
`;
export const updateCommXchargeDHrusd2 = /* GraphQL */ `
  mutation UpdateCommXchargeDHrusd2($input: UpdateCommXchargeDHrusd2Input!) {
    updateCommXchargeDHrusd2(input: $input) {
      active
      begda
      dtype
      endda
      id
      mandt
      rep_name
      us_name
      
    }
  }
`;
export const updateCommXchargeDJ1bbranch = /* GraphQL */ `
  mutation UpdateCommXchargeDJ1bbranch(
    $input: UpdateCommXchargeDJ1bbranchInput!
  ) {
    updateCommXchargeDJ1bbranch(input: $input) {
      branch
      bukrs
      cgc_branch
      dtype
      id
      mandt
      name
      
    }
  }
`;
export const updateCommXchargeDKNA1 = /* GraphQL */ `
  mutation UpdateCommXchargeDKNA1($input: UpdateCommXchargeDKNA1Input!) {
    updateCommXchargeDKNA1(input: $input) {
      dtype
      id
      kunnr
      mandt
      vbund
      
    }
  }
`;
export const updateCommXchargeDKnb1 = /* GraphQL */ `
  mutation UpdateCommXchargeDKnb1($input: UpdateCommXchargeDKnb1Input!) {
    updateCommXchargeDKnb1(input: $input) {
      bukrs
      dtype
      id
      kunnr
      mandt
      name1
      
    }
  }
`;
export const updateCommXchargeDLFA1 = /* GraphQL */ `
  mutation UpdateCommXchargeDLFA1($input: UpdateCommXchargeDLFA1Input!) {
    updateCommXchargeDLFA1(input: $input) {
      dtype
      erdat
      id
      lifnr
      loevm
      mandt
      vbund
      
    }
  }
`;
export const updateCommXchargeDLFB1 = /* GraphQL */ `
  mutation UpdateCommXchargeDLFB1($input: UpdateCommXchargeDLFB1Input!) {
    updateCommXchargeDLFB1(input: $input) {
      bukrs
      dtype
      id
      lifnr
      mandt
      name1
      
    }
  }
`;
export const updateCommXchargeDMara = /* GraphQL */ `
  mutation UpdateCommXchargeDMara($input: UpdateCommXchargeDMaraInput!) {
    updateCommXchargeDMara(input: $input) {
      bismt
      dtype
      ersda
      extwg
      id
      laeda
      maktx
      mandt
      matkl
      matnr
      mtart
      
    }
  }
`;
export const updateCommXchargeDReportdashboard = /* GraphQL */ `
  mutation UpdateCommXchargeDReportdashboard(
    $input: UpdateCommXchargeDReportdashboardInput!
  ) {
    updateCommXchargeDReportdashboard(input: $input) {
      actionAwaiting
      actionAwaitingEmailAddr
      actionType
      apRequestSubmittedDate
      apRequestorEmailId
      apRequestorId
      apRequestor_dateOfEntry
      apRequestor_mgremailaddress
      apRequestor_mgrid
      apSAPDocNo
      apSAPErrorMessage
      ap_doc_created_at
      ap_doc_created_by
      ap_doc_created_on
      ap_pay_term
      ap_pay_term_desc
      ap_posting_period
      ap_posting_year
      apapprover1_comments
      apapprover1_dateOfApproval
      apapprover1_dateOfEntry
      apapprover1_emailAddr
      apapprover1_id
      apapprover1_isdelegator
      apapprover1_lvl
      apapprover1_mgremailaddress
      apapprover1_mgrid
      apapprover1_name
      apapprover1_status
      apapprover2_comments
      apapprover2_dateOfApproval
      apapprover2_dateOfEntry
      apapprover2_emailAddr
      apapprover2_id
      apapprover2_isdelegator
      apapprover2_lvl
      apapprover2_mgremailaddress
      apapprover2_mgrid
      apapprover2_name
      apapprover2_status
      apapprover3_comments
      apapprover3_dateOfApproval
      apapprover3_dateOfEntry
      apapprover3_emailAddr
      apapprover3_id
      apapprover3_isdelegator
      apapprover3_lvl
      apapprover3_mgremailaddress
      apapprover3_mgrid
      apapprover3_name
      apapprover3_status
      apapprover4_comments
      apapprover4_dateOfApproval
      apapprover4_dateOfEntry
      apapprover4_emailAddr
      apapprover4_id
      apapprover4_isdelegator
      apapprover4_lvl
      apapprover4_mgremailaddress
      apapprover4_mgrid
      apapprover4_name
      apapprover4_status
      apapprover5_comments
      apapprover5_dateOfApproval
      apapprover5_dateOfEntry
      apapprover5_emailAddr
      apapprover5_id
      apapprover5_isdelegator
      apapprover5_lvl
      apapprover5_mgremailaddress
      apapprover5_mgrid
      apapprover5_name
      apapprover5_status
      apresult
      arRequestSubmittedDate
      arRequestorEmailId
      arRequestorId
      arSAPDocNo
      arSAPErrorMessage
      ar_doc_created_at
      ar_doc_created_by
      ar_doc_created_on
      ar_pay_term
      ar_pay_term_desc
      ar_posting_period
      ar_posting_year
      arapprover1_comments
      arapprover1_dateOfApproval
      arapprover1_dateOfEntry
      arapprover1_emailAddr
      arapprover1_id
      arapprover1_isdelegator
      arapprover1_lvl
      arapprover1_mgremailaddress
      arapprover1_mgrid
      arapprover1_name
      arapprover1_status
      arapprover2_comments
      arapprover2_dateOfApproval
      arapprover2_dateOfEntry
      arapprover2_emailAddr
      arapprover2_id
      arapprover2_isdelegator
      arapprover2_lvl
      arapprover2_mgremailaddress
      arapprover2_mgrid
      arapprover2_name
      arapprover2_status
      arapprover3_comments
      arapprover3_dateOfApproval
      arapprover3_dateOfEntry
      arapprover3_emailAddr
      arapprover3_id
      arapprover3_isdelegator
      arapprover3_lvl
      arapprover3_mgremailaddress
      arapprover3_mgrid
      arapprover3_name
      arapprover3_status
      arapprover4_comments
      arapprover4_dateOfApproval
      arapprover4_dateOfEntry
      arapprover4_emailAddr
      arapprover4_id
      arapprover4_isdelegator
      arapprover4_lvl
      arapprover4_mgremailaddress
      arapprover4_mgrid
      arapprover4_name
      arapprover4_status
      arapprover5_comments
      arapprover5_dateOfApproval
      arapprover5_dateOfEntry
      arapprover5_emailAddr
      arapprover5_id
      arapprover5_isdelegator
      arapprover5_lvl
      arapprover5_mgremailaddress
      arapprover5_mgrid
      arapprover5_name
      arapprover5_status
      arresult
      currAPApproverLVL
      currARApproverLVL
      currencyType
      dig_sign
      exchangeRate
      id
      invoiceDate
      invoiceNo
      postingDate
      receivingCompanyCode
      recvBusinessPlace
      recvCountryCode
      recvCurrAmt
      requestCompletionDate
      requestDate
      requestDescription
      requestId
      requestType
      sAPFailureMessage
      sendBusinessPlace
      sendCountryCode
      sendCurrAmt
      sendingCompanyCode
      stateName
      status
      taskToken
      totalAPApproverLVL
      totalARApproverLVL
      totalAmt
      uniq_doc
      
    }
  }
`;
export const updateCommXchargeDSkb1 = /* GraphQL */ `
  mutation UpdateCommXchargeDSkb1($input: UpdateCommXchargeDSkb1Input!) {
    updateCommXchargeDSkb1(input: $input) {
      bukrs
      dtype
      id
      mandt
      saknr
      txt20
      
    }
  }
`;
export const updateCommXchargeDT001 = /* GraphQL */ `
  mutation UpdateCommXchargeDT001($input: UpdateCommXchargeDT001Input!) {
    updateCommXchargeDT001(input: $input) {
      bukrs
      butxt
      city1
      country
      dtype
      house_num1
      id
      land1
      mandt
      region
      street
      vbList
      waers
      wfvar
      
    }
  }
`;
export const updateCommXchargeDT006A = /* GraphQL */ `
  mutation UpdateCommXchargeDT006A($input: UpdateCommXchargeDT006AInput!) {
    updateCommXchargeDT006A(input: $input) {
      dtype
      id
      mandt
      msehi
      msehl
      mseht
      spras
      
    }
  }
`;
export const updateCommXchargeDT007A = /* GraphQL */ `
  mutation UpdateCommXchargeDT007A($input: UpdateCommXchargeDT007AInput!) {
    updateCommXchargeDT007A(input: $input) {
      dtype
      id
      kalsm
      land1
      mandt
      mwart
      mwskz
      text1
      xinact
      
    }
  }
`;
export const updateCommXchargeDTcurc = /* GraphQL */ `
  mutation UpdateCommXchargeDTcurc($input: UpdateCommXchargeDTcurcInput!) {
    updateCommXchargeDTcurc(input: $input) {
      currdec
      id
      mandt
      waers
      
    }
  }
`;
export const updateCommXchargeDTradingpartner = /* GraphQL */ `
  mutation UpdateCommXchargeDTradingpartner(
    $input: UpdateCommXchargeDTradingpartnerInput!
  ) {
    updateCommXchargeDTradingpartner(input: $input) {
      companydesc
      fitcode
      id
      marscode
      tradingpartner
      
    }
  }
`;
export const updateCommXchargeDUsr01 = /* GraphQL */ `
  mutation UpdateCommXchargeDUsr01($input: UpdateCommXchargeDUsr01Input!) {
    updateCommXchargeDUsr01(input: $input) {
      bname
      dcpfm
      decDesc
      dtype
      id
      mandt
      
    }
  }
`;
export const updateCommXchargeDZfinApproval = /* GraphQL */ `
  mutation UpdateCommXchargeDZfinApproval(
    $input: UpdateCommXchargeDZfinApprovalInput!
  ) {
    updateCommXchargeDZfinApproval(input: $input) {
      butxt
      company_code
      dtype
      emp_id
      emp_name
      id
      land1
      level1_id
      level1_name
      level2_id
      level2_name
      level3_id
      level3_name
      level4_id
      level4_name
      level5_id
      level5_name
      mandt
      rel_grp
      
    }
  }
`;
